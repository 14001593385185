import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { DisplayType } from '../../../../core/types/display.type';
import { DisplayService } from '../../../../shared/services/display.service';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss']
})
export class FinalComponent {
  public display: DisplayType | null = this.displayService.display;
  code: string;
  isCopied = false;
  constructor(
    private readonly displayService: DisplayService,
    private route: ActivatedRoute,
    private clipboard: Clipboard
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.getReferenceCode();
  }

  getReferenceCode() {
    this.route.params.subscribe((params) => {
      if (params['deliveryFormCode']) this.code = params['deliveryFormCode'];
    });
  }

  setTimeoutId: ReturnType<typeof setTimeout> = null;
  copyText(text: string) {
    this.clipboard.copy(text);
    this.isCopied = true;
    clearTimeout(this.setTimeoutId);
    this.setTimeoutId = setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }
}
