import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../shared/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loaderService: LoaderService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // let url: string = environment.baseUrl;
    // if (url.substring(-1) === '/') url = url.substring(0, url.length - 1);
    // const urls: string[] = [
    //   'Contracts',
    //   'Categories/search/',
    // ].map((u: string) => url + u);

    // urls.forEach((u: string) => {
      //   if (req.url.indexOf(u) === 0) loading = false;
      // });
      let loading: boolean = true;

    if (loading) this.loaderService.setLoading('http', true);
    return next
      .handle(req)
      .pipe(finalize(() => this.loaderService.setLoading('http', false)));
  }
}
