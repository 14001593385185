import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconBtnType } from '../../../../core/consts/jobs.const';
import { DisplayType } from '../../../../core/types/display.type';
import { DisplayService } from '../../../../shared/services/display.service';
import { JobService } from '../../../../core/services/job.service';
import {
  IJobCategory,
  IJobCategoryResponse,
  IJobResponse,
} from '../../../../core/interfaces/job.interface';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
})
export class JobListComponent implements OnInit {
  public display: DisplayType | null = this.displayService.display;
  IconBtnType = IconBtnType;
  isGrid = true;
  jobCategories: IJobCategory[];
  jobs: IJobResponse[];
  jobCategoryName: string = 'All';
  featured:IJobResponse;
  constructor(
    private readonly displayService: DisplayService,
    private router: Router,
    private jobService: JobService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.getJobCategories();
    this.getJobs();
  }

  getJobCategories() {
    this.jobService.getJobCategories().subscribe((res) => {
      this.jobCategories = res.response;
      this.jobCategories.unshift({ name: 'All', isSelect: true });
    });
  }

  getJobs(categoryId?: number) {
    let params;
    categoryId ? (params = `categoryId=${categoryId}`) : (params = '');
    this.jobService.getJobs(params).subscribe((res) => {
      this.jobs = res.response;
      this.featured = res.response.filter((n) => {
        return n.is_featured === true;
      });
    });
  }

  selectIconBtnType(IconBtnTypeId: number) {
    this.IconBtnType.forEach((item, i) => {
      item.isSelect = IconBtnTypeId === i;
    });
  }

  selectJobCategory(JobCategory: IJobCategory) {
    this.jobCategories.forEach((i) => (i.isSelect = false));
    JobCategory.isSelect = true;
    JobCategory.name === 'All' ? this.getJobs() : this.getJobs(JobCategory.id);
  }

  changeLayout() {
    this.isGrid = !this.isGrid;
  }

  JobCategoryChoice(JobCategoryName:string) {
    this.jobCategoryName = JobCategoryName;
    const jobCategory = this.jobCategories.find((n) => {
      return n.name === this.jobCategoryName;
    });
    jobCategory.name === 'All' ? this.getJobs() : this.getJobs(jobCategory.id);
  }
}
