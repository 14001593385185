import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IContactUsRequest } from '../../../../core/interfaces/general.interface';
import { ContactUsService } from '../../../../core/services/general/contact-us.service';
import { NotificationHandlerService } from '../../../../core/services/helper/notification-handler.service';
import { DisplayType } from '../../../../core/types/display.type';
import { DisplayService } from '../../../../shared/services/display.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss'],
})
export class DeliveryFormComponent implements OnInit {
  radioValue = '1';
  public display: DisplayType | null = this.displayService.display;
  deliveryForm: FormGroup;
  isSubmitted = false;
  contactUsSubmitted = false;
  code: string;
  defaultPrefix = '519';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly displayService: DisplayService,
    private readonly contactUsService: ContactUsService,
    private readonly notificationHandlerService: NotificationHandlerService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.deliveryForm = new FormGroup({
      firstname: new FormControl(null, [Validators.required]),
      lastname: new FormControl(null, [Validators.required]),
      phone: new FormControl(this.defaultPrefix, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      line1: new FormControl(null),
      city: new FormControl(null),
      state: new FormControl(null),
      postalCode: new FormControl(null),
      companyName: new FormControl(null),
      domainName: new FormControl(null),
      estimate: new FormControl(null),
      time: new FormControl(null),
      information: new FormControl(null, Validators.required),
    });
  }
  onContactSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid) {
      const contentString = `
      Line1: ${form.value.line1?form.value.line1:''}
      City: ${form.value.city?form.value.city:''}
      State: ${form.value.state?form.value.state:''}
      Postal Code: ${form.value.postalCode?form.value.postalCode:''}
      Company Name: ${form.value.companyName?form.value.companyName:''}
      Domain Name: ${form.value.domainName?form.value.domainName:''}
      Estimate: ${form.value.estimate?form.value.estimate:''}
      Time: ${form.value.time?form.value.time:''}
      Information: ${form.value.information}
    `;
      const data: IContactUsRequest = {
        name: form.value.firstname + form.value.lastname,
        phone: form.value.phone.toString(),
        email: form.value.email,
        content: contentString,
      };
      this.contactUsService.createContactus(data).subscribe((res) => {
        if (res.status === 200) {
          this.contactUsSubmitted = true;
          this.code = res.response.code;
          localStorage.setItem('deliveryFormCode', JSON.stringify(this.code));
          this.notificationHandlerService.showNotification(
            'success',
            'Success',
            'Your message is sent!'
          );
          this.router.navigate(
            ['../delivery-final', { deliveryFormCode: this.code }],
            {
              relativeTo: this.route,
            }
          );
          form.reset();
          Object.keys(form.controls).forEach((key) => {
            form.get(key).setErrors(null);
          });
        }
      });
    } else {
      return;
    }
  }
}
