export const ResourceItems = [
  {
    name: 'Downloads',
    route: '/',
    img: 'https://prestige-movers.s3.amazonaws.com/resources/Icons_Dowloads.png',
    descrition:
      'A selection of downloadable resources including forms, checklists and publications',
  },
  {
    name: 'Blog',
    route: '/post-list',
    img: 'https://prestige-movers.s3.amazonaws.com/resources/Icons_Blog.png',
    descrition:
      'Our blog covers a wide range of topics related to our services to provide guidance and inspiration',
  },
  {
    name: 'FAQ',
    route: '/faq',
    img: 'https://prestige-movers.s3.amazonaws.com/resources/Icons_LastMinuteMove.png',
    descrition: `A list of our clients' Frequently Asked Questions about our services here at Prestige`,
  },
  {
    name: 'Terms and Policies',
    route: '/privacy-policy',
    img: 'https://prestige-movers.s3.amazonaws.com/resources/Icons_PrivacyPolicy.png',
    descrition:
      'Here you can go through our terms of service, policies, and other important legal information',
  },
  {
    name: 'Storage Calculator',
    route: '/',
    img: 'https://prestige-movers.s3.amazonaws.com/resources/Icons_StorageCalculator.png',
    descrition:
      'A useful calculator designed to help you choose the storage size',
  },
  {
    name: 'Packing Supply Calculator',
    route: '/',
    img: 'https://prestige-movers.s3.amazonaws.com/resources/Icons_PackingSupplyCalculator.png',
    descrition:
      'A calculator designed to help you estimate the amount of packing supplies to save time and money',
  },
];
