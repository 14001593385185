<section>
  <div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
      <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
        <img src="https://prestige-movers.s3.amazonaws.com/resources/Resources_3xl.png" alt="blog" class="m-auto" />
      </ng-container>
      <ng-container *ngIf="display === 'TABLET'">
        <img src="https://prestige-movers.s3.amazonaws.com/resources/Resources_medium.png" alt="blog" class="m-auto" />
      </ng-container>
      <ng-container *ngIf="display === 'MOBILE'">
        <img src="https://prestige-movers.s3.amazonaws.com/resources/Resources_small.png" alt="blog"
          class="m-auto w-full" />
      </ng-container>
    </div>
    <div class="-translate-y-1/4 laptop:-translate-y-2/4 text-center px-4 md:px-0">
      <h3 class="text-red-5 text-[32px] leading-[38.4px]">Blog</h3>
      <h1
        class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px] 3xl:text-[64px] 3xl:leading-[64px]">
        View all posts
      </h1>
    </div>
  </div>
</section>

<div class="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-4 gap-9 mx-auto md:px-8 laptop:px-16 laptop:w-11/12">
  <div class="3xl:col-span-3 px-6 md:px-0">
    <ng-container *ngIf="display === 'MOBILE'">
      <div class="mb-6">
        <div
          class="relative mb-5 flex items-center justify-between overflow-hidden bg-dun-0.5 border border-dun-5 rounded-lg">
          <input type="text" name="search"
            class="w-full bg-transparent !border-0 placeholder:!text-[#363636] rounded-none focus:!bg-transparent focus:ring-0"
            placeholder="What are you looking for?" #searchInput />
          <button class="bg-red-5 p-2" (click)="onSearch(searchInput.value)">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5 14.5H14.71L14.43 14.23C15.4439 13.054 16.0011 11.5527 16 10C16 8.71442 15.6188 7.45772 14.9046 6.3888C14.1903 5.31988 13.1752 4.48676 11.9874 3.99479C10.7997 3.50282 9.49279 3.37409 8.23192 3.6249C6.97104 3.8757 5.81285 4.49477 4.90381 5.40381C3.99477 6.31285 3.3757 7.47104 3.1249 8.73192C2.87409 9.99279 3.00282 11.2997 3.49479 12.4874C3.98676 13.6752 4.81988 14.6903 5.8888 15.4046C6.95772 16.1188 8.21442 16.5 9.5 16.5C11.11 16.5 12.59 15.91 13.73 14.93L14 15.21V16L19 20.99L20.49 19.5L15.5 14.5ZM9.5 14.5C7.01 14.5 5 12.49 5 10C5 7.51 7.01 5.5 9.5 5.5C11.99 5.5 14 7.51 14 10C14 12.49 11.99 14.5 9.5 14.5Z"
                fill="#fff" />
            </svg>
          </button>
        </div>
        <nz-select [(ngModel)]="categoryId" (ngModelChange)="getPostbyCtegoryId($event)">
          <nz-option *ngFor="let category of categories" [nzValue]="category.id" [nzLabel]="category.name"></nz-option>
        </nz-select>
      </div>
    </ng-container>
    <ng-container *ngIf="posts">
      <div class="grid grid-cols-1 laptop:grid-cols-2 3xl:grid-cols-3 gap-9">
        <div class="grid grid-cols-1 gap-4 text-center cursor-pointer" *ngFor="let post of posts"
          [routerLink]="['/post-detail/', post.id, post.slug]">
          <!-- <img src="/assets/images/faq.svg" alt="" /> -->
          <img [src]="post?.image?.download_url" [alt]="post.name" />
          <p class="overflow-hidden w-full break-words truncate">
            {{ post?.headline }}
          </p>
          <h4 class="overflow-hidden w-full break-words truncate">
            {{ post?.name }}
          </h4>
        </div>
      </div>
    </ng-container>
    <nz-pagination class="my-16 text-center" [nzPageIndex]="paginationInfo?.from" [nzTotal]="paginationInfo?.total"
      [nzPageSize]="paginationInfo?.per_page" [nzSize]="'small'" [nzResponsive]="true"
      (nzPageIndexChange)="getNextPageData($event)"></nz-pagination>
  </div>
  <div class="flex flex-col gap-8 h-full bg-basic-0.5 md:bg-basic-0 p-4 md:p-0">
    <ng-container *ngIf="
        display === 'TABLET' || display === 'LAPTOP' || display === 'DESKTOP'
      ">
      <div>
        <div
          class="relative mb-5 flex items-center justify-between overflow-hidden bg-dun-0.5 border border-dun-5 rounded-lg">
          <input type="text" name="search"
            class="w-full bg-transparent !border-0 placeholder:!text-[#363636] rounded-none focus:!bg-transparent focus:ring-0"
            placeholder="What are you looking for?" #searchInput />
          <button class="bg-red-5 p-2" (click)="onSearch(searchInput.value)">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5 14.5H14.71L14.43 14.23C15.4439 13.054 16.0011 11.5527 16 10C16 8.71442 15.6188 7.45772 14.9046 6.3888C14.1903 5.31988 13.1752 4.48676 11.9874 3.99479C10.7997 3.50282 9.49279 3.37409 8.23192 3.6249C6.97104 3.8757 5.81285 4.49477 4.90381 5.40381C3.99477 6.31285 3.3757 7.47104 3.1249 8.73192C2.87409 9.99279 3.00282 11.2997 3.49479 12.4874C3.98676 13.6752 4.81988 14.6903 5.8888 15.4046C6.95772 16.1188 8.21442 16.5 9.5 16.5C11.11 16.5 12.59 15.91 13.73 14.93L14 15.21V16L19 20.99L20.49 19.5L15.5 14.5ZM9.5 14.5C7.01 14.5 5 12.49 5 10C5 7.51 7.01 5.5 9.5 5.5C11.99 5.5 14 7.51 14 10C14 12.49 11.99 14.5 9.5 14.5Z"
                fill="#fff" />
            </svg>
          </button>
        </div>
        <nz-select [(ngModel)]="categoryId" (ngModelChange)="getPostbyCtegoryId($event)">
          <nz-option *ngFor="let category of categories" [nzValue]="category.id" [nzLabel]="category.name"></nz-option>
        </nz-select>
      </div>
    </ng-container>
    <div class="mt-4">
      <h4>Featured Posts</h4>
      <div class="grid grid-cols-1 gap-4 mt-4 cursor-pointer" *ngFor="let post of Featured"
        [routerLink]="['/post-detail/', post.id, post.slug]">
        <img [src]="post?.image?.download_url" [alt]="post.name" />
        <p class="overflow-hidden w-full break-words truncate">
          {{ post?.headline }}
        </p>
        <h4 class="overflow-hidden w-full break-words truncate">
          {{ post?.name }}
        </h4>
      </div>
    </div>
    <div class="border-t border-basic-5 pt-5">
      <h4>Recent Posts</h4>
      <div class="grid grid-cols-3 gap-4 items-center my-6 cursor-pointer" *ngFor="let post of RecentPosts"
        [routerLink]="['/post-detail/', post.id, post.slug]">
        <img [src]="post?.image?.download_url" [alt]="post.name" />
        <div class="col-span-2">
          <p class="text-lg leading-[22.63px]">
            {{ post?.headline }}
          </p>
          <p> {{ post?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-subscription-form [title]="'Receive Blog Updates!'" [type]="'blog'"
  [description]="'Subscribe to our mailing list to receive updates about our latest articles!'"></app-subscription-form>