<div class="my-16">
    <div class="text-center px-4 md:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">open a Corporate account with prestige</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
  3xl:text-[64px] 3xl:leading-[64px]">Driving Your Business Forward!
        </h1>
    </div>
    <form [formGroup]="deliveryForm" (ngSubmit)="onContactSubmit(deliveryForm)" class="w-full">
        <div class="grid grid-cols-1 gap-6 md:w-9/12 px-4 md:px-0 md:mx-[100px] my-8 ">
            <h2 class="text-center laptop:w-[40%] laptop:mx-auto laptop:text-right mt-6 text-gray-5">Information</h2>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6 items-center">
                <h5
                    class="laptop:text-right laptop:col-span-2 after:content-['*'] after:ml-0.5 after:text-red-5 text-gray-5">
                    Name
                </h5>
                <div class="laptop:col-span-5 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div class="w-full">
                        <input type="text" class="w-full" placeholder="First Name" formControlName="firstname" required
                            [ngClass]="{
                          'invalid:border-red-5':
                            (deliveryForm.get('firstname').touched || isSubmitted) &&
                            deliveryForm.get('firstname').hasError('required')
                        }" />
                        <span *ngIf="
                          (deliveryForm.get('firstname').touched || isSubmitted) &&
                          deliveryForm.get('firstname').hasError('required')
                        " class="text-red-5 text-sm leading-4">
                            first name is required
                        </span>
                    </div>
                    <div class="w-full"> <input type="text" placeholder="Last Name" class="w-full"
                            formControlName="lastname" required [ngClass]="{
                        'invalid:border-red-5':
                          (deliveryForm.get('lastname').touched || isSubmitted) &&
                          deliveryForm.get('lastname').hasError('required')
                      }" />
                        <span *ngIf="
                        (deliveryForm.get('lastname').touched || isSubmitted) &&
                        deliveryForm.get('lastname').hasError('required')
                      " class="text-red-5 text-sm leading-4">
                            last name is required
                        </span>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6 items-center">
                <h5
                    class="laptop:text-right laptop:col-span-2 after:content-['*'] after:ml-0.5 after:text-red-5 text-gray-5">
                    Email
                </h5>
                <div class="laptop:col-span-5 grid grid-cols-1 gap-6">
                    <input type="email" placeholder="Email Address" formControlName="email" required [ngClass]="{
                      'invalid:border-red-5':
                        (deliveryForm.get('email').touched || isSubmitted) &&
                        (deliveryForm.get('email').hasError('required') ||
                          deliveryForm.get('email').hasError('email'))
                    }" />
                    <span *ngIf="
                      (deliveryForm.get('email').touched || isSubmitted) &&
                      deliveryForm.get('email').hasError('required')
                    " class="text-red-5 text-sm leading-4">
                        email is required
                    </span>
                    <span *ngIf="
                      (deliveryForm.get('email').touched || isSubmitted) &&
                      deliveryForm.get('email').hasError('email')
                    " class="text-red-5 text-sm leading-4">
                        Enter a valid Email
                    </span>
                </div>
            </div>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6 items-center">
                <h5
                    class="laptop:text-right laptop:col-span-2 after:content-['*'] after:ml-0.5 after:text-red-5 text-gray-5">
                    Phone
                </h5>
                <div class="laptop:col-span-5 grid grid-cols-1 gap-6">
                    <input class="w-full" type="text" mask="(000) 000-0000" name="phoneNumber"
                        placeholder="Phone Number" required formControlName="phone" [ngClass]="{
              'invalid:border-red-5':
                (deliveryForm.get('phone').touched || isSubmitted) &&
                deliveryForm.get('phone').hasError('required')
            }" />
                    <span *ngIf="
                (deliveryForm.get('phone').touched || isSubmitted) &&
                deliveryForm.get('phone').value === '519'
              " class="text-red-5 text-sm leading-4">
                        Phone is required
                    </span>
                </div>
            </div>
            <h2 class="text-center mt-6 text-gray-5 laptop:w-[60%] laptop:mx-auto laptop:text-right">Organization
                Information</h2>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6 items-center">
                <h5 class="laptop:text-right laptop:col-span-2 text-gray-5">Company name</h5>
                <div class="laptop:col-span-5 grid grid-cols-1 gap-6">
                    <input type="text" placeholder="Company name" formControlName="companyName" required [ngClass]="{
                        'invalid:border-red-5':
                          (deliveryForm.get('companyName').touched || isSubmitted) &&
                          deliveryForm.get('companyName').hasError('required')
                      }" />
                    <span *ngIf="
                        (deliveryForm.get('companyName').touched || isSubmitted) &&
                        deliveryForm.get('companyName').hasError('required')
                      " class="text-red-5 text-sm leading-4">
                        company name is required
                    </span>
                </div>
            </div>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6 items-center">
                <h5 class="laptop:text-right laptop:col-span-2 text-gray-5">Company Domain name</h5>
                <div class="laptop:col-span-5 grid grid-cols-1 gap-6">
                    <input type="text" placeholder="Company Domain name" formControlName="domainName" required
                        [ngClass]="{
                        'invalid:border-red-5':
                          (deliveryForm.get('domainName').touched || isSubmitted) &&
                          deliveryForm.get('domainName').hasError('required')
                      }" />
                    <span *ngIf="
                        (deliveryForm.get('domainName').touched || isSubmitted) &&
                        deliveryForm.get('domainName').hasError('required')
                      " class="text-red-5 text-sm leading-4">
                        domain name is required
                    </span>
                </div>
            </div>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-6">
                <h5
                    class="after:content-['*'] after:ml-0.5 after:text-red-5 laptop:text-right laptop:col-span-2 text-gray-5">
                    Address</h5>
                <div class="laptop:col-span-5 grid grid-cols-2 gap-6">
                    <div>
                        <input type="text" placeholder="Line" class="w-full" formControlName="line1" required [ngClass]="{
                            'invalid:border-red-5':
                              (deliveryForm.get('line1').touched || isSubmitted) &&
                              deliveryForm.get('line1').hasError('required')
                          }" />
                        <span *ngIf="
                            (deliveryForm.get('line1').touched || isSubmitted) &&
                            deliveryForm.get('line1').hasError('required')
                          " class="text-red-5 text-sm leading-4">
                            State is required
                        </span>
                    </div>
                    <div>
                        <input type="text" placeholder="City" class="w-full" formControlName="city" required [ngClass]="{
                            'invalid:border-red-5':
                              (deliveryForm.get('city').touched || isSubmitted) &&
                              deliveryForm.get('city').hasError('required')
                          }" />
                        <span *ngIf="
                            (deliveryForm.get('city').touched || isSubmitted) &&
                            deliveryForm.get('city').hasError('required')
                          " class="text-red-5 text-sm leading-4">
                            City is required
                        </span>
                    </div>
                    <div>
                        <input type="text" placeholder="State" class="w-full" formControlName="state" required
                            [ngClass]="{
                            'invalid:border-red-5':
                              (deliveryForm.get('state').touched || isSubmitted) &&
                              deliveryForm.get('state').hasError('required')
                          }" />
                        <span *ngIf="
                            (deliveryForm.get('state').touched || isSubmitted) &&
                            deliveryForm.get('state').hasError('required')
                          " class="text-red-5 text-sm leading-4">
                            State is required
                        </span>
                    </div>
                    <div>
                        <input type="text" placeholder="Postal Code" class="w-full" formControlName="postalCode"
                            required [ngClass]="{
                            'invalid:border-red-5':
                              (deliveryForm.get('postalCode').touched ||
                                isSubmitted) &&
                              deliveryForm.get('postalCode').hasError('required')
                          }" />
                        <span *ngIf="
                            (deliveryForm.get('postalCode').touched || isSubmitted) &&
                            deliveryForm.get('postalCode').hasError('required')
                          " class="text-red-5 text-sm leading-4">
                            Postal Code is required
                        </span>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6 items-center">
                <h5 class="laptop:text-right laptop:col-span-2 text-gray-5">Estimated deliveries / week</h5>
                <div class="laptop:col-span-5 grid grid-cols-1 gap-6">
                    <input type="text" formControlName="estimate" required [ngClass]="{
                    'invalid:border-red-5':
                      (deliveryForm.get('estimate').touched || isSubmitted) &&
                      deliveryForm.get('estimate').hasError('required')
                  }" />
                    <span *ngIf="
                    (deliveryForm.get('estimate').touched || isSubmitted) &&
                    deliveryForm.get('estimate').hasError('required')
                  " class="text-red-5 text-sm leading-4">
                        estimate is required
                    </span>
                </div>
            </div>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6 items-center my-2">
                <h5
                    class="after:content-['*'] after:ml-0.5 after:text-red-5 laptop:text-right laptop:col-span-2 text-gray-5">
                    Number
                    of business locations</h5>
                <nz-radio-group formControlName="time"
                    class="laptop:col-span-5 flex flex-wrap md:flex-nowrap gap-8 md:gap-12 ">
                    <label nz-radio nzValue="1">1</label>
                    <label nz-radio nzValue="2 - 4">2 - 4</label>
                    <label nz-radio nzValue="5 - 9">5 - 9</label>
                    <label nz-radio nzValue="10+">10+</label>
                </nz-radio-group>
            </div>
            <div class="grid grid-cols-1 laptop:grid-cols-7 gap-2 md:gap-6">
                <div class="laptop:col-span-2">
                    <h5 class="laptop:text-right text-gray-5">Additional information </h5>
                    <p class="laptop:text-right text-gray-5">Please provide a short description of what we can help
                        you with, details about your business, requirements, etc.</p>
                </div>
                <div class="laptop:col-span-5 grid grid-cols-1 gap-6">
                    <textarea name="message" id="" cols="30" rows="10" placeholder="Message" class="w-full"
                        formControlName="information" required [ngClass]="{
                      'invalid:border-red-5':
                        (deliveryForm.get('information').touched || isSubmitted) &&
                        deliveryForm.get('information').hasError('required')
                    }"></textarea>
                    <span *ngIf="
                      (deliveryForm.get('information').touched || isSubmitted) &&
                      deliveryForm.get('information').hasError('required')
                    " class="text-red-5 text-sm leading-4">
                        information is required
                    </span>
                </div>
            </div>
        </div>

        <div class="flex flex-col md:flex-row justify-center items-center gap-8 my-8">
            <button type="submit" class="md:w-auto text-center uppercase bg-red-5 text-basic-0">Submit</button>
        </div>
    </form>
</div>