interface IconBtnInterface {
  id: number;
  name: string;
  class: string;
  isSelect: boolean;
}

export const IconBtnType: IconBtnInterface[] = [
  { id: 0, name: 'list', class: 'fa-table-list', isSelect: false },
  { id: 1, name: 'grid', class: 'fa-table-cells', isSelect: true },
];

