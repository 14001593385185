import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericDataResponse } from '../interfaces/generic-response.interface';
import { ResourceService } from './resource.service';
import {
  ICreateJobApplicationRequest,
  ICreateJobApplicationResponse,
  IJobCategoryResponse,
  IJobResponse,
} from '../interfaces/job.interface';

@Injectable({
  providedIn: 'root',
})
export class JobService extends ResourceService<
  IJobCategoryResponse | IJobResponse
> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getJobCategories(): Observable<GenericDataResponse<IJobCategoryResponse[]>> {
    return this.get('/job-categories');
  }

  getJobs(params?): Observable<GenericDataResponse<IJobResponse[]>> {
    return this.getByParams('/jobs', params);
  }

  getJobById(id: number): Observable<GenericDataResponse<IJobResponse>> {
    return this.getById('/jobs', id);
  }

  createJobApplication(
    data: ICreateJobApplicationRequest
  ): Observable<GenericDataResponse<ICreateJobApplicationResponse>> {
    return this.post('/job-applications', data);
  }
}
