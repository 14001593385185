<section>
  <div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
      <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'
        ">
        <img src="https://prestige-movers.s3.amazonaws.com/resources/Resources_3xl.png" alt="resources"
          class="m-auto" />
      </ng-container>
      <ng-container *ngIf="
        display === 'TABLET'
      ">
        <img src="https://prestige-movers.s3.amazonaws.com/resources/Resources_medium.png" alt="resources"
          class="m-auto" />
      </ng-container>
      <ng-container *ngIf="display === 'MOBILE'">
        <img src="https://prestige-movers.s3.amazonaws.com/resources/Resources_small.png" alt="resources"
          class="m-auto w-full" />
      </ng-container>
    </div>
    <div class="-translate-y-2/4 laptop:-translate-y-2/4 text-center px-4 md:px-0">
      <h3 class="text-red-5 text-[32px] leading-[38.4px]">
        Resources
      </h3>
      <h1
        class="text-gray-7 text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px] 3xl:text-[64px] 3xl:leading-[64px]">
        Tips, Tools and More!
      </h1>
    </div>
  </div>
</section>
<section class="pb-14 w-full md:w-4/5 laptop:w-full 3xl:w-4/5 m-auto mt-5 laptop:mt-0">
  <div class="container mx-auto px-4 md:px-8 laptop:px-16">
    <div class="grid grid-cols-1 md:grid-cols-2 laptop:grid-cols-3 gap-8 3xl:gap-16 gap-y-12 items-start">
      <div *ngFor="let resource of resources" (click)="selectService(resource.name)"
        class="flex flex-col items-center justify-center cursor-pointer">
        <img [src]="resource.img" [alt]="resource.name" />
        <h4 class="mt-5 mb-2 text-gray-7 text-center">{{ resource.name }}</h4>
        <p class="text-lg text-center text-basic-5">
          {{ resource.descrition }}
        </p>
      </div>
    </div>
  </div>
</section>