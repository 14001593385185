<section class="bg-light1 py-14">
  <div class="container mx-auto md:px-8 laptop:px-16">
    <div class="w-full md:w-4/5 3xl:w-3/5 m-auto px-4">
      <div>
        <h1
          class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px] 3xl:text-[64px] 3xl:leading-[64px]"
        >
          {{job?.name}}
        </h1>
        <p class="text-gray-5 mt-4 mb-8 text-xl">Posted on {{ job?.ui_created_at }}</p>
      </div>
      <div>
        <h3 class="text-darkNavy">Job details</h3>
        <div class="p-6 border-[1px] border-dun-5 rounded-lg bg-dun-2">
          <ul class="grid grid-cols-1 gap-y-3 text-gray-5">
            <li class="flex gap-4 md:gap-8">
              <span
                class="text-sm inline-block font-bold basis-1/4 md:basis-1/5 laptop:basis-1/6"
                >Job Type</span
              >
              <p class="text-sm basis-3/4 md:basis-4/5 laptop:basis-5/6">
                {{ job?.type }}
              </p>
            </li>
            <li class="flex gap-4 md:gap-8">
              <span
                class="text-sm inline-block font-bold basis-1/4 md:basis-1/5 laptop:basis-1/6"
                >Benefits</span
              >
              <p class="text-sm basis-3/4 md:basis-4/5 laptop:basis-5/6">
                {{ job?.benefits }}
              </p>
            </li>
            <li class="flex gap-4 md:gap-8">
              <span
                class="text-sm inline-block font-bold basis-1/4 md:basis-1/5 laptop:basis-1/6"
                >Schedule</span
              >
              <p class="text-sm basis-3/4 md:basis-4/5 laptop:basis-5/6">
                {{ job?.schedule }}
              </p>
            </li>
            <li class="flex gap-4 md:gap-8">
              <span
                class="text-sm inline-block font-bold basis-1/4 md:basis-1/5 laptop:basis-1/6"
                >Salary</span
              >
              <p class="text-sm basis-3/4 md:basis-4/5 laptop:basis-5/6">
                {{ job?.salary }}
              </p>
            </li>
            <li class="flex gap-4 md:gap-8">
              <span
                class="text-sm inline-block font-bold basis-1/4 md:basis-1/5 laptop:basis-1/6"
                >Supplemental pay types
              </span>
              <p class="text-sm basis-3/4 md:basis-4/5 laptop:basis-5/6">
                {{ job?.supplemental_pay_types }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="my-8">
        <h3 class="text-darkNavy">Job Summary</h3>
        <div>
          <p class="text-gray-5 text-lg">
            {{ job?.description }}
          </p>
        </div>
      </div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 items-center gap-4 w-3/5 mx-auto my-14"
      >
        <button
          type="button"
          [routerLink]="['/jobs']"
          class="w-full text-center uppercase bg-transparent text-gray-7 border-[1px] border-gray-7"
        >
          Back
        </button>
        <button
          type="button"
          [routerLink]="['/formal/job-application/application-form']"
          class="w-full text-center uppercase bg-red-5 text-basic-0"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</section>
