import { Component, OnInit } from '@angular/core';
import { DisplayType } from '../../../../core/types/display.type';
import { DisplayService } from '../../../../shared/services/display.service';
import { JobService } from '../../../../core/services/job.service';
import { IJobResponse } from '../../../../core/interfaces/job.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
})
export class JobDetailComponent implements OnInit {
  public display: DisplayType | null = this.displayService.display;
  job: IJobResponse;
  constructor(
    private readonly displayService: DisplayService,
    private activatedRoute: ActivatedRoute,
    private jobService: JobService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((res) => {
      const id = +res['params']['id'];
      this.getJobById(id);
    });
  }

  getJobById(id) {
    this.jobService.getJobById(id).subscribe((res) => {
      this.job = res.response;
    });
  }

  apply(jobId:number){}
}
