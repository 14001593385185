import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  constructor(private router: Router) {}
  isPostRoute = false;
  ngOnInit(): void {
    if (this.router.url.substring(0, 13) === '/post-detail/')
      this.isPostRoute = true;
  }
}
