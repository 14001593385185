<section class="bg-light1 md:bg-basic-0">
  <div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
      <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
        <img src="https://prestige-movers.s3.amazonaws.com/contact-us/ContactUs_3xl.png" alt="ContactUs"
          class="m-auto" />
      </ng-container>
      <ng-container *ngIf="display === 'TABLET'">
        <img src="https://prestige-movers.s3.amazonaws.com/contact-us/ContactUs_medium.png" alt="ContactUs"
          class="m-auto" />
      </ng-container>
      <ng-container *ngIf="display === 'MOBILE'">
        <img src="https://prestige-movers.s3.amazonaws.com/contact-us/ContactUs_small.png" alt="ContactUs"
          class="m-auto w-full" />
      </ng-container>
    </div>
    <div class="-translate-y-1/4 laptop:-translate-y-2/4 text-center px-4 md:px-0">
      <h3 class="text-red-5 text-[32px] leading-[38.4px]">Contact us</h3>
      <h1
        class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px] 3xl:text-[64px] 3xl:leading-[64px]">
        Drop us an email
      </h1>
      <p class="mx-auto md:w-11/12 laptop:w-9/12 text-center text-lg mt-4">
        We're thrilled to hear from you and eager to address any questions, comments, or concerns you may have. Here's
        how you can get in touch with us:
      </p>
    </div>
    <div class="mx-auto w-[90%] laptop:w-4/5 py-10">
      <div class="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-4 gap-14 md:gap-10 laptop:gap-12 3xl:gap-8 items-start">
        <div class="flex flex-col gap-6 justify-center items-center">
          <img src="https://prestige-movers.s3.amazonaws.com/contact-us/Icons_Email.png" alt="Icons_Email" />
          <h2>Email</h2>
          <p class="text-center text-base md:min-h-[105px] 3xl:min-h-[168px]">
            You can email us at info@prestigemovers.ca. We make every effort to
            respond to emails within 24-48 hours.
          </p>
          <a href="mailto: info@prestigemovers.ca">
            <button class="md:w-auto text-center uppercase bg-red-5 text-basic-0">
              Email us
            </button>
          </a>
        </div>
        <div class="flex flex-col gap-6 justify-center items-center">
          <img src="https://prestige-movers.s3.amazonaws.com/contact-us/Icons_Phone.png" alt="Icons_Phone" />
          <h2>Phone</h2>
          <p class="text-center text-base md:min-h-[105px] 3xl:min-h-[168px]">
            If you prefer to speak with someone over the phone, you can reach us
            at
            <a [href]="'tel:' + info?.home_page[0].value">(519) 444-4444</a>. Our
            customer service representatives are available 8 AM to 8 PM ET to
            assist you with any inquiries.
          </p>
          <a [href]="'tel:' + info?.home_page[0].value">
            <button class="md:w-auto text-center uppercase bg-red-5 text-basic-0">
              Call us
            </button>
          </a>
        </div>
        <div class="flex flex-col gap-6 justify-center items-center">
          <img src="https://prestige-movers.s3.amazonaws.com/contact-us/Icons_Chat.png" alt="Icons_Chat" />
          <h2>Live Chat</h2>
          <p class="text-center text-base md:min-h-[105px] 3xl:min-h-[168px]">
            We also offer a live chat feature on our website. Simply click on
            the chat icon located in the bottom right-hand corner of your screen
            to speak with a representative.
          </p>
          <button (click)="loadAndOpenChat()" class="md:w-auto text-center uppercase bg-red-5 text-basic-0">
            Let’s talk
          </button>
        </div>
        <div class="flex flex-col gap-6 justify-center items-center">
          <img src="https://prestige-movers.s3.amazonaws.com/contact-us/Icons_Social.png" alt="Icons_Social" />
          <h2>Social Media</h2>
          <p class="text-center text-base md:min-h-[105px] 3xl:min-h-[168px]">
            Connect with us on social media! Follow us on
            <a target="_blank" href="https://www.instagram.com/prestigemovers.ca"
              class="border-b-[1px] border-basic-5">Instagram</a>
            and
            <a target="_blank" href="https://www.facebook.com/prestigemovingdelivery"
              class="border-b-[1px] border-basic-5">Facebook</a>
            to stay up-to-date on our latest news, promotions, and product
            launches.
          </p>
          <div class="flex justify-center items-center gap-4">
            <a target="_blank" href="https://www.instagram.com/prestigemovers.ca">
              <button class="md:w-auto text-center uppercase bg-red-5 text-basic-0 px-3">
                <a target="_blank" href="https://www.instagram.com/prestigemovers.ca"><i
                    class="fa-brands fa-instagram text-basic-0 text-2xl !leading-none"></i></a>
              </button>
            </a>
            <button class="md:w-auto text-center uppercase bg-red-5 text-basic-0 px-3">
              <a target="_blank" href="https://www.facebook.com/prestigemovingdelivery"><i
                  class="fa-brands fa-square-facebook text-basic-0 text-2xl !leading-none"></i></a>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!contactUsSubmitted">
      <div class="mx-auto md:[60%] laptop:w-[60%] 3xl:w-[40%] px-4 laptop:px-0 text-center py-16">
        <h1 class="text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
          Web Contact Form
        </h1>
        <p class="text-lg text-gray-5">
          Fill out our online contact form below, and we'll respond to your
          message as soon as possible. Please provide as much detail as possible
          to help us better understand your inquiry.
        </p>
        <form [formGroup]="contactForm" (ngSubmit)="onContactSubmit(contactForm)"
          class="flex flex-col gap-4 mt-8 text-left">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <input type="text" class="w-full" name="name" placeholder="Name" formControlName="name" required
                [ngClass]="{
                  'invalid:border-red-5':
                    (contactForm.get('name').touched || isSubmitted) &&
                    contactForm.get('name').hasError('required')
                }" />
              <span *ngIf="
                  (contactForm.get('name').touched || isSubmitted) &&
                  contactForm.get('name').hasError('required')
                " class="text-red-5 text-sm leading-4">
                name is required
              </span>
            </div>
            <div>
              <input class="w-full" type="text" name="phoneNumber" placeholder="Phone Number" required appPhonePrefix
                formControlName="phone" [ngClass]="{
                  'invalid:border-red-5':
                    (contactForm.get('phone').touched || isSubmitted) &&
                    contactForm.get('phone').hasError('required')
                }" />
              <span *ngIf="
                  (contactForm.get('phone').touched || isSubmitted) &&
                  contactForm.get('phone').hasError('required')
                " class="text-red-5 text-sm leading-4">
                phone is required
              </span>
            </div>
          </div>
          <div>
            <input class="w-full" type="email" name="email" placeholder="Email Address" formControlName="email" required
              [ngClass]="{
                'invalid:border-red-5':
                  (contactForm.get('email').touched || isSubmitted) &&
                  (contactForm.get('email').hasError('required') ||
                    contactForm.get('email').hasError('email'))
              }" />
            <span *ngIf="
                (contactForm.get('email').touched || isSubmitted) &&
                contactForm.get('email').hasError('required')
              " class="text-red-5 text-sm leading-4">
              email is required
            </span>
            <span *ngIf="
                (contactForm.get('email').touched || isSubmitted) &&
                contactForm.get('email').hasError('email')
              " class="text-red-5 text-sm leading-4">
              Enter a valid Email
            </span>
          </div>
          <div>
            <textarea name="message" id="" cols="30" rows="10" placeholder="Message" class="w-full"
              formControlName="message" required [ngClass]="{
                'invalid:border-red-5':
                  (contactForm.get('message').touched || isSubmitted) &&
                  contactForm.get('message').hasError('required')
              }"></textarea>
            <span *ngIf="
                (contactForm.get('message').touched || isSubmitted) &&
                contactForm.get('message').hasError('required')
              " class="text-red-5 text-sm leading-4">
              message is required
            </span>
          </div>
          <p class="text-center text-gray-5 text-lg">
            We value your feedback and are always looking for ways to improve
            our products and services. If you have any suggestions or comments,
            please don't hesitate to let us know. We're committed to providing
            excellent customer service and look forward to hearing from you.
          </p>
          <button type="submit" class="text-center mx-auto inline-block uppercase bg-red-5 text-basic-0">
            send message
          </button>
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="contactUsSubmitted">
      <div class="mx-auto md:[60%] laptop:w-[60%] 3xl:w-[40%] px-4 laptop:px-0 text-center py-16">
        <h1 class="text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
          Web Contact Form
        </h1>
        <p class="text-gray-5 text-lg">
          Fill out our online contact form below, and we'll respond to your
          message as soon as possible. Please provide as much detail as possible
          to help us better understand your inquiry.
        </p>
        <div class="flex flex-col gap-4 mt-8 bg-light1 text-left rounded-lg border-[1px] border-dun-5 p-8">
          <img src="https://prestige-movers.s3.amazonaws.com/contact-us/Icons_Sent.png" alt="Icons_Sent" width="140px"
            class="mx-auto inline-block" />
          <p class="text-gray-7">
            Thank you for reaching out to Prestige Moving & Delivery Support.
            Your inquiry has been successfully submitted and a member of our
            customer service team will be in touch with you shortly to assist
            you with your request. To help you keep track of your inquiry, we've
            generated a unique reference number for you:
          </p>
          <h2
            class="bg-dun-2 my-5 mx-auto text-center text-gray-7 py-6 w-[272px] laptop:w-[304px] rounded-[10px] px-4 cursor-pointer">
            {{ code }}
          </h2>
          <p class="text-gray-7">
            Please use this number when you contact us to check the status of
            your inquiry or if you have any further questions or concerns. You
            can also track the status of your inquiry on our website by entering
            your reference number in the tracking bar. Thank you for your
            interest in our services, and we look forward to assisting you soon.
            Best regards, Prestige Moving & Delivery Support
          </p>
        </div>
        <p class="mt-8 text-gray-5 text-lg">
          We value your feedback and are always looking for ways to improve our
          products and services. If you have any suggestions or comments, please
          don't hesitate to let us know. We're committed to providing excellent
          customer service and look forward to hearing from you.
        </p>
      </div>
    </ng-container>
  </div>
</section>