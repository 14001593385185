import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationHandlerService } from '../services/helper/notification-handler.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationHandler: NotificationHandlerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // if (event instanceof HttpResponse) {
          //   const method = request.method;
          //   const successMessageMethods = ["POST", "PUT", "PATCH"];
          //   if (successMessageMethods.some(m => m === method) &&
          //     event.body?.succeeded == true &&
          //     event.body?.message) {
          //     this.notificationHandler.showNotification();
          //   }
          // }
        },
        (err: HttpErrorResponse) => {
          if(err.status === 403) return
          if (err instanceof HttpErrorResponse) {
            let errors = err.error?.errors;
            if (errors && Object.keys(errors)?.length > 0) {
              for (let er in errors) {
                for (let i of errors[er]) {
                  this.notificationHandler.showNotification(
                    'error',
                    'Error',
                    i
                  );
                }
              }
            } else {
              this.notificationHandler.showNotification(
                'error',
                'Error',
                err.error.message
              );
            }
          }
        }
      )
    );
  }
}
