import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../resource.service';
import { IBlogResponse } from '../../../interfaces/blog.interface';
import { GenericDataResponse } from '../../../interfaces/generic-response.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlogService extends ResourceService<IBlogResponse> {
  private readonly endPoint = '/blog';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getPostList(params?): Observable<GenericDataResponse<IBlogResponse>> {
    return this.getByParams(this.endPoint,params);
  }

  getPostById(
    id: number,
    slug: string
  ): Observable<GenericDataResponse<IBlogResponse>> {
    return this.get(this.endPoint + '/' + id + '/' + slug);
  }

  searchPost(param: string): Observable<GenericDataResponse<IBlogResponse>> {
    return this.getByParams(this.endPoint, param);
  }
}
