export const ServiceItems = [
  { name: 'Moving', route: '/moving' },
  { name: 'Delivery', route: '/delivery-service' },
  { name: 'Trucking', route: '/trucking' },
  { name: 'Packing', route: '/packing-service' },
  { name: 'Furniture Assembly', route: '/furniture-assembly' },
  { name: 'Storage', route: '/storage' },
  { name: 'Boxes and Supplies', route: '/boxes-supplies' },
  { name: 'Junk Removal', route: '/junk-removal' },
  { name: 'Shipping and Courier', route: '/shipping-courier' },
];

export const QuikLinks = [
  { name: 'Recourses', route: '/resources' },
  { name: 'Careers', route: '/careers' },
  { name: 'Terms and Policies', route: '/privacy-policy' },
  { name: 'Gift Cards', route: 'https://squareup.com/gift/MLVDKT3QPH0DM/order' },
  { name: 'Payment  Portal', route: '/payment/retrieve-payment' },
  { name: 'Universal Tracking', route: '' },
  { name: 'Insurance', route: '/insurance' },
  { name: 'Claims', route: '/formal/claim/policy' },
  { name: 'Franchise', route: '/formal/franchise/description' },
];

