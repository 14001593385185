import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from '../resource.service';
import { GenericDataResponse } from '../../interfaces/generic-response.interface';
import { IVehicleResponse } from '../../interfaces/vehicle.interface';


@Injectable({
  providedIn: 'root',
})
export class VehicleService extends ResourceService<IVehicleResponse> {
  private readonly endPoint = '/vehicle-types';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getVehicles(): Observable<GenericDataResponse<IVehicleResponse[]>> {
    return this.get(this.endPoint)
  }
}
