import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { LoaderService } from './shared/services/loader.service';
import { Subscription } from 'rxjs';
import { DisplayService } from './shared/services/display.service';
import { DisplayType } from './core/types/display.type';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private loadingChanged: Subscription;
  public loading: boolean = false;
  private displayChanged: Subscription;
  public display: DisplayType | null = this.displayService.display;

  constructor(
    private readonly loaderService: LoaderService,
    private readonly displayService: DisplayService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadingChanged = this.loaderService.onChanged.subscribe(
      (loading: boolean) => {
        this.loading = loading;
        this.cdRef.detectChanges();
      }
    );

    this.displayChanged = this.displayService.onDisplayChanged.subscribe(
      (display: DisplayType) => {
        this.display = display;
      }
    );

  }

  ngOnDestroy() {
    this.loadingChanged.unsubscribe();
    this.displayChanged.unsubscribe();
  }
}
