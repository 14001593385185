import { Component } from '@angular/core';
import { InsurancePlans, Items } from '../../../core/consts/insurance.const';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
})
export class InsuranceComponent {
  public display: DisplayType | null = this.displayService.display;
  InsurancePlans = InsurancePlans;
  Items = Items;

  constructor(private readonly displayService: DisplayService) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }
}
