<section>
  <div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
      <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
        <img src="https://prestige-movers.s3.amazonaws.com/career/Career_3xl.png" alt="career" class="m-auto" />
      </ng-container>
      <ng-container *ngIf="display === 'TABLET'">
        <img src="https://prestige-movers.s3.amazonaws.com/career/Career_medium.png" alt="career" class="m-auto" />
      </ng-container>
      <ng-container *ngIf="display === 'MOBILE'">
        <img src="https://prestige-movers.s3.amazonaws.com/career/Career_small.png" alt="career"
          class="m-auto w-full" />
      </ng-container>
    </div>
    <div class="text-center w-11/12 laptop:w-4/5 3xl:w-3/5 m-auto mt-6 md:mt-0 md:-translate-y-1/4 laptop:-translate-y-2/4 3xl:-translate-y-1/4">
      <h3 class="text-red-5 text-[27px] leading-[27px] md:text-[32px] md:leading-[38.4px]">
        Our core values
      </h3>
      <h1 class="text-gray-7 text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
        About us and our services
      </h1>
      <p class="text-center mt-5 text-gray-7 text-lg">
        Here at Prestige, we come together as a family to work towards our
        clients’ satisfaction. Prestige Moving & Delivery is a fast-growing
        organization that offers fast, efficient, and reliable moving and
        delivery services in London and the surrounding areas. Our #1 priority
        is to listen to your needs and provide you with the best possible
        solutions.
      </p>
    </div>
  </div>
</section>
<section class="my-20">
  <div class="container mx-auto px-4 md:px-8 laptop:px-16">
    <div class="gap-4 grid grid-cols-1 laptop:grid-cols-3 items-start">
      <div class="flex items-center flex-col md:flex-row laptop:flex-col">
        <img src="https://prestige-movers.s3.amazonaws.com/career/Career_Vision.png" alt="Career_Vision" />
        <div class="text-center md:text-left laptop:text-center">
          <h3 class="text-darkNavy text-[32px] leading-[38.4px] md:text-[32px] md:leading-[38.4px]">
            Our Vision
          </h3>
          <p class="mt-5 text-laptop text-[#363636] w-11/12 mx-auto md:w-full laptop:mx-auto laptop:w-11/12 text-lg">
            As a fast-growing company in Canada, we are focused on building and maintaining a strong professional
            relationship with our clients. We value their feedback and use it to constantly improve our services. Our
            goal is to utilize the latest tools, equipment, and navigation systems to enhance the quality of our work
            and ensure a smooth and stress-free experience for our clients
          </p>
        </div>
      </div>
      <div class="flex items-center flex-col md:flex-row laptop:flex-col">
        <img src="https://prestige-movers.s3.amazonaws.com/career/Career_Mission.png" alt="Career_Mission" />
        <div class="text-center md:text-left laptop:text-center">
          <h3 class="text-darkNavy text-[32px] leading-[38.4px] md:text-[32px] md:leading-[38.4px]">
            Our Mission
          </h3>
          <p class="mt-5 text-laptop text-[#363636] w-11/12 mx-auto md:w-full laptop:mx-auto laptop:w-11/12 text-lg">
            Prestige Moving & Delivery aims to offer a one-stop shop for all our clients’ moving and delivery needs. We
            take pride in bringing courtesy, integrity, and empathy to our work, and we are committed to customizing our
            services to fit our customers’ unique needs and expectations.
          </p>
        </div>
      </div>
      <div class="flex items-center flex-col md:flex-row laptop:flex-col">
        <img src="https://prestige-movers.s3.amazonaws.com/career/Career_Goal.png" alt="Career_Goal" />
        <div class="text-center md:text-left laptop:text-center">
          <h3 class="text-darkNavy text-[32px] leading-[38.4px] md:text-[32px] md:leading-[38.4px]">
            Our Goal
          </h3>
          <p class="mt-5 text-laptop text-[#363636] w-11/12 mx-auto md:w-full laptop:mx-auto laptop:w-11/12 text-lg">
            At Prestige Moving & Delivery, we are passionate about what we do and are dedicated to providing exceptional
            service. Our goal is to take care of all moving and delivery services our clients need, so they can focus on
            what matters most.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="mt-28">
  <div class="container mx-auto px-4 md:px-8 laptop:px-16">
    <div class="text-center w-full laptop:w-3/5 m-auto my-20">
      <h3 class="text-orange text-[32px] leading-[38.4px]">Prestige is now offering career opportunities</h3>
      <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
        Why work for us?
      </h1>
      <p class="text-center mt-5 text-laptop text-gray-7 text-lg">
        Prestige Moving & Delivery seeks to grow its highly professional staff.
        Join our family and we will provide you with great opportunities to
        realize your full potential, reach your career ambitions and most
        importantly, learn about the business!
      </p>
    </div>

    <div class="gap-4 grid grid-cols-1 laptop:grid-cols-2 items-center">
      <div class="order-2 laptop:order-1">
        <h1 class="text-darkNavy hidden laptop:block text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
          Are you ...?
        </h1>
        <img src="https://prestige-movers.s3.amazonaws.com/career/why_us.png" alt="why_us" class="m-auto" />
      </div>

      <div class="gap-5 md:gap-4 grid grid-cols-1 order-1 laptop:order-2">
        <h1 class="text-darkNavy block laptop:hidden text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
          Are you ...?
        </h1>
        <div>
          <h3 class="text-darkNavy text-[32px] leading-[38.4px]">
            Hardworking
          </h3>
          <p class="text-lg text-laptop text-[#363636]">
            We are looking for hardworking and self-motivated individuals with
            great practical and social skills.
          </p>
        </div>
        <div>
          <h3 class="text-darkNavy text-[32px] leading-[38.4px]">A Leader</h3>
          <p class="text-lgtext-laptop text-[#363636]">
            Great leadership skills will ensure your success within our company
            and open doors to new career opportunities.
          </p>
        </div>
        <div>
          <h3 class="text-darkNavy text-[32px] leading-[38.4px]">
            Self-motivated
          </h3>
          <p class="text-laptop text-lg text-[#363636]">
            Our top priority is to make sure our customers’ requests are
            delivered fully, safely and punctually. So, we are looking for
            people who are highly committed to their job and are always striving
            to improve their performance. Being self-motivated is thus the key
            to reach higher levels in our company.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-dun-5 py-28">
  <div class="container mx-auto px-4 md:px-8 laptop:px-16">
    <div class="text-center w-full laptop:w-4/5 3xl:w-3/5 m-auto">
      <h3 class="text-red-5 text-[32px] leading-[38.4px]">
        Job opportunities
      </h3>
      <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
        Join our Team
      </h1>
      <p class="text-center text-lg mt-5 text-laptop text-gray-5 my-10">
        Prestige is always seeking dedicated individuals who want to advance their careers. We believe in "moving people
        forward," which is why your personal development within our company will help us achieve overall success. If you
        would like to join our highly motivated team, please send us your resume.
      </p>
      <a routerLink="/jobs"
        class="rounded-lg px-5 py-3 bg-red-5 text-basic-0 hover:bg-red-5 duration-300 inline-flex items-center uppercase">Job
        Opportunities</a>
    </div>
  </div>
</section>
<app-subscription-form [title]="'get job posting aletrs'" [type]="'job'"
  [description]="'Subscribe to our mailing list to receive updates about new career opportunities right in your email inbox!'"></app-subscription-form>