import { Component, OnInit } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';

@Component({
  selector: 'app-shipping-courier',
  templateUrl: './shipping-courier.component.html',
  styleUrls: ['./shipping-courier.component.scss'],
})
export class ShippingCourierComponent implements OnInit {
  public display: DisplayType | null = this.displayService.display;
  shippingForm: FormGroup;
  constructor(private readonly displayService: DisplayService) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.shippingForm = new FormGroup({
      country: new FormControl(''),
      postCode: new FormControl(''),
    });
  }

  onShippingSubmit(form) {}
}
