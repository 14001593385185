<section>
  <div class="container mx-auto px-4 md:px-8 3xl:px-16">
    <div class="text-center mt-24 md:mt-32">
      <h3 class="text-orange text-[32px] leading-[38.4px]">
        JOBs AND AVAILABLE POSITIONS
      </h3>
      <h1
        class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px] 3xl:text-[64px] 3xl:leading-[64px]">
        Driving, Moving, corporate, or sales
      </h1>
    </div>
  </div>
</section>
<section class="select my-10 mx-auto w-full md:w-3/4 laptop:w-full">
  <div class="container mx-auto px-4 md:px-8 laptop:px-4 3xl:px-16">
    <div class="gap-x-6 flex justify-center items-center">
      <div class="gap-3 flex items-center border-r-[1px] border-gray-5 pr-6">
        <button (click)="changeLayout()" type="button" *ngFor="let type of IconBtnType"
          (click)="selectIconBtnType(type.id)"
          class="w-fit text-center p-2 md:!py-auto md:px-4 rounded md:rounded-[10px]" [ngClass]="
            type.isSelect === true
              ? 'bg-red-5 text-basic-0'
              : 'bg-transparent text-gray-7 border-[1px] border-gray-7'
          ">
          <i class="fa-solid text-2xl" [ngClass]="type.class"></i>
        </button>
      </div>
      <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
        <div class="col-span-6 gap-4 flex items-center">
          <button type="button" *ngFor="let jobCategory of jobCategories" (click)="selectJobCategory(jobCategory)"
            class="w-fit text-center uppercase" [ngClass]="
              jobCategory.isSelect === true
                ? 'bg-red-5 text-basic-0'
                : 'bg-transparent text-gray-7 border-[1px] border-gray-7'
            ">
            <i *ngIf="jobCategory.isSelect === true" class="fa-solid fa-check text-xs text-basic-0"></i>
            {{ jobCategory.name }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="display === 'TABLET' || display === 'MOBILE'">
        <div class="col-span-2">
          <nz-select [(ngModel)]="jobCategoryName" (ngModelChange)="JobCategoryChoice($event)">
            <nz-option *ngFor="let jobCategory of jobCategories" [nzValue]="jobCategory.name"
              [nzLabel]="jobCategory.name"></nz-option>
          </nz-select>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<ng-container *ngIf="featured?.is_featured">
  <section class="my-10">
    <div class="container mx-auto px-4 md:px-8 3xl:px-16">
      <div
        class="p-5 gap-5 grid grid-cols-1 md:grid-cols-3 items-center bg-dun-2 rounded-lg border-r-[1px] border-dun-5">
        <div class="bg-gray-4 h-[150px] w-full md:h-full text-center">
          <img src="" alt="" />
        </div>
        <div class="md:col-span-2">
          <h2 class="text-gray-5">{{ featured?.name }}</h2>
          <p class="mt-3 mb-5">
            {{ featured?.description }}
          </p>
          <button [routerLink]="['/job-detail', featured.id]"
            class="bg-transparent text-gray-7 border-[1px] border-gray-7 uppercase mx-auto block md:inline-block">
            Apply now
          </button>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="isGrid">
  <section class="my-10">
    <div class="container mx-auto px-4 md:px-8 3xl:px-16">
      <div class="gap-8 grid grid-cols-1 laptop:grid-cols-2 items-center">
        <div *ngFor="let job of jobs" class="border-[1px] border-dun-5 rounded-lg p-6 min-h-[350px]">
          <h2 class="text-[27px] leading-[27px] md:text-[40px] md:leading-10 text-gray-5">{{ job.name }}</h2>
          <span class="text-gray-4 my-3 block md:text-lg">{{ job.ui_created_at }}</span>
          <p class="md:text-xl text-gray-5">{{ job.salary }}</p>
          <p class="md:text-xl text-gray-5">{{ job.type }}</p>
          <p nz-typography nzEllipsis [nzEllipsisRows]="4" [nzContent]="job.description"
            class="mb-5 mt-3 text-gray-7 md:text-lg"></p>
          <button class="text-gray-4 uppercase bg-transparent border-[1px] border-gray-4"
            [routerLink]="['/job-detail', job.id]">
            View job details
          </button>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="!isGrid">
  <section class="my-10">
    <div class="container mx-auto px-4 md:px-8 3xl:px-16">
      <div class="grid grid-cols-1 items-center">
        <div *ngFor="let job of jobs"
          class="gap-4 grid grid-cols-1 laptop:grid-cols-3 border-b-[1px] border-dun-5 p-4 items-center">
          <h3
            class="text-gray-5 text-[24px] leading-[28.8px] md:text-[27px] md:leading-[27px] laptop:text-[32px] laptop:leading-[38.4px]">
            {{ job.name }}
          </h3>
          <div
            class="laptop:col-span-2 gap-4 grid grid-cols-3 md:flex laptop:grid laptop:grid-cols-4 items-center justify-between text-center">
            <span class="text-gray-4 my-3 block md:text-lg">{{ job.ui_created_at }}</span>
            <p class="text-sm md:text-lg text-gray-5">{{ job.salary }}</p>
            <p class="text-sm md:text-lg text-gray-5">{{ job.type }}</p>
            <ng-container *ngIf="display === 'MOBILE'">
              <button
                class="w-fit col-span-2 text-gray-4 uppercase px-2 text-center bg-transparent border-[1px] border-gray-4"
                [routerLink]="['/job-detail', job.id]">
                <i class="fa-solid fa-check text-xs text-gray-7"></i>
                details
              </button>
            </ng-container>
            <ng-container *ngIf="
                display === 'TABLET' ||
                display === 'LAPTOP' ||
                display === 'DESKTOP'
              ">
              <button
                class="text-gray-4 text-[18px] uppercase px-2 text-center bg-transparent border-[1px] border-gray-4"
                [routerLink]="['/job-detail', job.id]">
                <i class="fa-solid fa-check text-xs text-gray-7"></i>
                View job details
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<app-subscription-form [title]="'get job posting aletrs'" [type]="'job'"
  [description]="'Subscribe to our mailing list to receive updates about new career opportunities right in your email inbox!'"></app-subscription-form>