<div>
    <div class="container mx-auto md:px-8 laptop:px-16">
        <div class="min-h-[310px]">
            <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Banner___Header%201.png"
                    alt="Assembly" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'TABLET'">
                <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/JunkRemoval_Banner_medium.png"
                    alt="Assembly" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'MOBILE'">
                <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Banner___Header_M%201.png"
                    alt="Assembly" class="m-auto w-full">
            </ng-container>
        </div>
        <div class="text-center px-4 md:px-0 -translate-y-4 md:-translate-y-8 laptop:-translate-y-1/4">
            <h3 class="text-red-5 text-[32px] leading-[38.4px]">Junk Removal Division</h3>
            <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px]">Clearing your space, removing stress!
            </h1>
            <p class="mx-auto text-center laptop:w-11/12 3xl:w-10/12 text-lg ">The Junk Removal Division of
                Prestige Moving & Delivery in London is dedicated to providing an effortless and swift decluttering
                experience. Our competent team will handle all the lifting, loading, and hauling for you, and we offer
                transparent pricing to all our customers. Our services are available for both sole removal jobs or as a
                part of your delivery or moving service. With our dump trailers, bins, pick-up trucks, and cube trucks,
                we can manage removal jobs of any size. We are happy to help you get rid of any unwanted debris or
                items, even after your move or delivery. We also provide complimentary estimates, conduct donation runs,
                and work with local recyclers to dispose of your unwanted belongings in an environmentally-responsible
                way.
            </p>
        </div>
        <div class="mx-auto w-[90%] 3xl:w-9/12 px-6 py-10 laptop:py-12 mb-10">
            <div class="grid grid-cols-1 md:grid-cols-2 laptop:grid-cols-4 gap-12 my-8">
                <div class="flex flex-col items-center justify-center gap-8">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Icons___FreeEstimates%201.png"
                        alt="FreeEstimates">
                    <h5 class="text-center">Free Estimates</h5>
                </div>
                <div class="flex flex-col items-center justify-center gap-8">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Icons___SameDayorScheduledJunkRemoval%201.png"
                        alt="SameDayorScheduledJunkRemoval">
                    <h5 class="text-center">Same-day or scheduled junk removal</h5>
                </div>
                <div class="flex flex-col items-center justify-center gap-8">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Icons___SingleItemPickup%201.png"
                        alt="SingleItemPickup">
                    <h5 class="text-center">Single Item Pickup</h5>
                </div>
                <div class="flex flex-col items-center justify-center gap-8">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Icons___EasyOnlineBooking%201.png"
                        alt="EasyOnlineBooking">
                    <h5 class="text-center">Easy Online Booking</h5>
                </div>
            </div>
            <div class=" text-center 3xl:w-10/12 mx-auto">
                <p class="my-16 text-lg">We strive to make the removal process as hassle-free as possible. We offer a
                    free estimate, and you can schedule your request for the same day or for a future date via our
                    customer-friendly online booking. We also offer single item pickups.
                </p>
                <button routerLink="/services/junk-removal" class="md:w-auto text-center uppercase bg-red-5 text-basic-0">Book
                    Now</button>
            </div>
        </div>

        <div class="mx-auto laptop:w-11/12 3xl:w-4/5 px-4 laptop:px-0 mb-14">
            <div class="my-8 mt-16">
                <h1 class="text-darkNavy text-center text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
                3xl:text-[64px] 3xl:leading-[64px]">Our Services
                </h1>
            </div>
            <div class="grid grid-cols-1 gap-y-12">
                <div
                    class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center 3xl:justify-items-start">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Images___AppliancesRemoval%202.png"
                        alt="AppliancesRemoval">
                    <div>
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Appliances Removal</h2>
                        <p class="text-lg text-center 3xl:text-left">We offer professional and hassle-free removal
                            of old and unwanted appliances. Our team is equipped to handle all types of appliances,
                            including refrigerators, ovens, washers, dryers, and more. We ensure that these items are
                            disposed of properly and in an environmentally-friendly way.</p>
                    </div>
                </div>
                <div
                    class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center 3xl:justify-items-start">
                    <div class="order-2 3xl:order-1">
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Furniture Removal</h2>
                        <p class="text-lg text-center 3xl:text-left">Our team is here to help you get rid of any
                            unwanted furniture items in your home or office. We can remove all types of furniture,
                            including sofas, chairs, tables, and more. With our help, you can free up space in your home
                            or office and get rid of unwanted clutter.
                        </p>
                    </div>
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Images___FurnitureRemoval%201.png"
                        alt="FurnitureRemoval" class="order-1 3xl:order-2">
                </div>
                <div
                    class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center 3xl:justify-items-start">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Images___E-wasteRemoval%201.png"
                        alt="E-wasteRemoval">
                    <div>
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            E-waste Removal</h2>
                        <p class="text-lg text-center 3xl:text-left">E-waste, such as old computers and electronic
                            devices, should be disposed of properly to avoid harm to the environment. We offer e-waste
                            removal services to help you get rid of these items safely and responsibly.</p>
                    </div>
                </div>
                <div
                    class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center 3xl:justify-items-start">
                    <div class="order-2 3xl:order-1">
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Property Clean-Out</h2>
                        <p class="text-lg text-center 3xl:text-left">We understand that property owners may need to
                            clean out their space from time to time. Whether you're moving out of an apartment,
                            renovating a property, or clearing out a space after a tenant moves out, we offer property
                            clean-out services to make the process simple and efficient.
                        </p>
                    </div>
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Images___PropertyCleanOut%201.png"
                        alt="PropertyCleanOut" class="order-1 3xl:order-2">
                </div>
                <div
                    class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center 3xl:justify-items-start">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Images___HoardingAssistance%201.png"
                        alt="HoardingAssistance">
                    <div>
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Hoarding Assistance</h2>
                        <p class="text-lg text-center 3xl:text-left">Our team is here to help those who struggle with
                            hoarding disorder. We offer compassionate and non-judgmental assistance to help individuals
                            in hoarding situations declutter and organize their space.</p>
                    </div>
                </div>
                <div
                    class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center 3xl:justify-items-start">
                    <div class="order-2 3xl:order-1">
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Donation Runs</h2>
                        <p class="text-lg text-center 3xl:text-left">We believe in giving back to the community,
                            which is why we offer donation run services. We can help you transport your gently-used
                            items to local donation centers, ensuring that they are given a second life and benefit
                            those in need.
                        </p>
                    </div>
                    <img src="https://prestige-movers.s3.amazonaws.com/services-junk-removal/PrestigeMovers_Website_JunkRemoval_Images___DonationRun%201.png"
                        alt="DonationRun" class="order-1 3xl:order-2">
                </div>
            </div>
        </div>
    </div>
</div>