import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource.service';

@Injectable({
  providedIn: 'root',
})
export class PrivacyPolicyService extends ResourceService<any, any> {
  private readonly endPoint = '/privacy-policy';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getPrivacyPolicy() {
    return this.get(this.endPoint).pipe();
  }
}
