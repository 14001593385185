import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource.service';

@Injectable({
  providedIn: 'root',
})
export class FaqService extends ResourceService<any, any> {
  private readonly endPoint = '/faq';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getFaqs() {
    return this.get(this.endPoint);
  }
}
