<div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
        <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-storage/Storage_3xl.png" alt="Storage"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'TABLET'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-storage/Storage_medium.png" alt="Storage"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-storage/Storage_small.png" alt="Storage"
                class="m-auto w-full">
        </ng-container>
    </div>
    <div class="-translate-y-5 text-center px-4 md:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">Storage</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">Secure, Convenient, and Hassle-Free Storage
            Solutions
        </h1>
        <p class="mx-auto text-lg 3xl:w-9/12 text-center my-3 mb-24">At Prestige Moving & Delivery in London, we
            understand that finding reliable and secure storage solutions can be a daunting task. That's why we offer
            two kinds of storage services to accommodate our customers' unique needs: outdoor storage and indoor
            storage. Choose us for your storage needs and experience peace of mind, knowing that your belongings are in
            very good hands.</p>
    </div>
    <div class="mx-auto laptop:w-11/12 px-4 laptop:px-0 mb-16">
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
        3xl:text-[64px] 3xl:leading-[64px] mx-auto laptop:w-9/12 my-16 text-center">Our Services
        </h1>
        <div class="grid grid-cols-1 gap-y-20">
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <img src="https://prestige-movers.s3.amazonaws.com/services-storage/Images_AllInclusiveStorage.png"
                    alt="AllInclusiveStorage">
                <div class="flex flex-col items-center 3xl:items-start">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        All-Inclusive Storage</h2>
                    <p class="text-lg text-center 3xl:text-left">Our all-inclusive storage plan is a hassle-free and
                        convenient service for all your storage needs. Our team will collect your items from your
                        location and transport them to our secure storage facility. When you need an item, simply
                        contact us and our staff will promptly deliver it back to you. With our convenient storage
                        service, you don't have to worry about the hassle of transporting your items to a storage unit,
                        and you can easily access your stored belongings whenever you need them. Our storage facilities
                        are safe, secure and equipped with climate control features to ensure the protection of your
                        items. Contact us today to learn more about this convenient storage service at Prestige!
                    </p>
                    <button routerLink="/services/storage" class="text-center uppercase bg-red-5 text-basic-0 my-8">Book
                        Now</button>
                </div>
            </div>
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start ">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        Storage Pods</h2>
                    <p class="text-lg text-center 3xl:text-left">Prestige Moving & Delivery offers a convenient
                        storage pod service for all your storage needs. Our storage pods are a great solution for anyone
                        who needs a secure and easy-to access storage option. Our team will deliver the storage pod to
                        your location, and you can take your time packing and loading it with your belongings. Once you
                        are done, we will transport the storage pod to our secure storage facility, where it will be
                        monitored and protected until you are ready to retrieve your items. Our storage pods are
                        weather-resistant and can surely accommodate your specific needs. With our storage pod service,
                        you can enjoy the convenience of having your belongings stored securely without worrying about
                        transporting them to a storage unit. Contact us today to learn more about our storage pod
                        service!
                    </p>
                    <button routerLink="/services/storage" class="text-center uppercase bg-red-5 text-basic-0 my-8">Book
                        Now</button>
                </div>
                <img src="https://prestige-movers.s3.amazonaws.com/services-storage/Images_StoragePods.png"
                    alt="StoragePods" class="order-1 laptop:order-2">
            </div>
        </div>
    </div>
</div>
<section class="bg-dun-2 py-28">
    <div class="container mx-auto px-4 md:px-8 laptop:px-16">
        <div class="text-center w-full laptop:w-4/5 3xl:w-3/5 m-auto">
            <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
                request a pick up or drop off
            </h1>
            <p class="text-center text-lg mt-5 text-laptop text-gray-5 my-10">
                We have made it easy for you to manage your items and storage containers with our online booking and
                request system. Simply select the appropriate option above and proceed with your request.
            </p>
            <div class="flex flex-col md:flex-row gap-8 items-center justify-center w-full">
                <button (click)="getStorageType('pick_up')"
                    class="rounded-lg px-5 py-3 bg-red-5 text-basic-0 hover:bg-red-5 hover:text-basic-0 duration-300 inline-flex items-center uppercase">
                    Request a pickup</button>
                <button (click)="getStorageType('drop_off')"
                    class="rounded-lg px-5 py-3 bg-red-5 text-basic-0 hover:bg-red-5 hover:text-basic-0 duration-300 inline-flex items-center uppercase">
                    request a drop off</button>
            </div>
        </div>
    </div>
</section>