import { Component } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { PrivacyPolicyService } from '../../../core/services/general/privacy-policy.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  public display: DisplayType | null = this.displayService.display;
  Items: any;
  privacyPolicy: any;
  categoryName: any;
  isActive = false;
  constructor(
    private readonly displayService: DisplayService,
    private PrivacyPolicyService: PrivacyPolicyService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.getPrivacyPolicy();
  }

  getPrivacyPolicy() {
    this.PrivacyPolicyService.getPrivacyPolicy().subscribe((res) => {
      this.privacyPolicy = res.response;
      this.getItems(this.privacyPolicy[0].name);
    });
  }

  onSearch(event: Event) {
    const searchQuery = (
      event.target as HTMLInputElement
    )?.value?.toLowerCase();
    if (!searchQuery) {
      this.getItems(this.categoryName);
    } else {
      this.Items = [];
      this.privacyPolicy.forEach((item: any) => {
        const matchingPrivacyPolicy = item.privacy_policies.filter((n: any) => {
          return (
            n.name.toLowerCase().includes(searchQuery) ||
            n.content.toLowerCase().includes(searchQuery)
          );
        });
        if (matchingPrivacyPolicy.length > 0) {
          matchingPrivacyPolicy.forEach((privacy: any) => {
            this.Items.push({
              name: privacy.name,
              content: privacy.content,
            });
          });
        }
      });
    }
  }

  getItems(categoryName: any) {
    if (typeof categoryName === 'string') {
      this.categoryName = categoryName;
    } else {
      this.categoryName = (categoryName.target as HTMLSelectElement)?.value;
    }
    this.Items = this.privacyPolicy.find(
      (n: any) => n.name === this.categoryName
    );
    this.activateButton(this.Items);
    return (this.Items = this.Items.privacy_policies);
  }

  activateButton(button: any): void {
    this.privacyPolicy.forEach((b: any) => (b.isActive = false));
    button.isActive = true;
  }
}
