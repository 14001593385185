import { Component } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';

@Component({
  selector: 'app-trucking',
  templateUrl: './trucking.component.html',
  styleUrls: ['./trucking.component.scss']
})
export class TruckingComponent {
  public display: DisplayType | null = this.displayService.display;
  constructor(private readonly displayService: DisplayService) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display
    });
  }
}
