<div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
        <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-moving/Moving_3xl.png" alt="Moving"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'TABLET'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-moving/Moving_medium.png" alt="Moving"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-moving/Moving_small.png" alt="Moving"
                class="m-auto w-full">
        </ng-container>
    </div>
    <div class="-translate-y-5 text-center px-4 laptop:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">About Our Moving Service</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto laptop:w-9/12">Prestige is all about providing a smooth moving
            experience!
        </h1>
        <p class="mx-auto text-lg laptop:w-11/12 3xl:w-9/12 text-center my-3 mb-20">Our top priority is protecting
            you from the stress of moving and your belongings from any kind of damage. The moving crew at Prestige are
            highly trained, have gone through a strict background check and will appear in our special uniform. Skilled
            at handling any type of goods, they have mastered the art of transportation.
            Our company offers competitive prices and, rest assured, there are no hidden fees. We are fully insured and
            our released-value insurance covers any move at no extra charge. Our high-quality equipment which includes
            rubber-backed runners, furniture pads and regularly sanitized trucks will guarantee a safe move. You can
            request any local or long-distance move here at Prestige.
        </p>
    </div>
    <div class="mx-auto laptop:w-11/12 px-4 laptop:px-0 mb-16">
        <h3 class="text-red-5 text-[32px] leading-[38.4px] text-center">Moving You Forward</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
        3xl:text-[64px] 3xl:leading-[64px] mx-auto laptop:w-9/12 mb-16 text-center">What Our Moving Service Includes
        </h1>
        <div class="grid grid-cols-1 gap-y-20">
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <img src="https://prestige-movers.s3.amazonaws.com/services-moving/Residential.png" alt="Residential">
                <div class="flex flex-col items-center 3xl:items-start">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        Residential Moving Service</h2>
                    <p class="text-lg text-center 3xl:text-left">No matter what kind of household moving you need,
                        big or small, local or long-distance, our moving crew will arrange it within the shortest time
                        frame and with the highest precision. Our movers will see to the safety of your furniture
                        through most meticulous handling and use of high quality supplies. We can also provide packing
                        and storage services for your convenience. Our fully-equipped movers and professional
                        consultants will make sure you’ll have a stress-free and even pleasant moving experience!
                    </p>
                </div>
            </div>
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start ">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        Commercial Moving Service</h2>
                    <p class="text-lg text-center 3xl:text-left">Moving is much more than just relocating when it
                        comes to your business, and we appreciate that. That’s why you can always customize your
                        commercial moves here at Prestige to experience the most efficient and smooth work-place
                        transition. Our professionals are skilled at handling office equipment, furniture, supplies and
                        electronics. By requesting a commercial moving service at Prestige, your new business location
                        will feel like an old one before you know it.
                    </p>
                </div>
                <img src="https://prestige-movers.s3.amazonaws.com/services-moving/Commercial.png" alt="Commercial"
                    class="order-1 3xl:order-2">
            </div>
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <img src="https://prestige-movers.s3.amazonaws.com/services-moving/SpecialtyItems.png"
                    alt="SpecialtyItems">
                <div class="flex flex-col items-center 3xl:items-start">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        Moving Specialty Items</h2>
                    <p class="text-lg text-center 3xl:text-left">No more worries about the pieces of furniture or
                        equipment that could be difficult to handle during your move! Our trained moving crew and
                        specially-designed vehicles can move anything for you, say an antique grand piano or a vending
                        machine! However delicate or tricky your belongings are, we can move them for you with utmost
                        care and precision.
                    </p>
                </div>
            </div>
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start ">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        Moving Arrangements for Students</h2>
                    <p class="text-lg text-center 3xl:text-left">Prestige Moving & Delivery understands the unique
                        needs of students when it comes to moving. Our specialized service for student moving ensures a
                        hassle-free and efficient relocation experience. Whether you're moving into a dormitory,
                        apartment, or off-campus housing, our professional team will handle all aspects of your move,
                        from packing and transportation to unloading and assembly, allowing you to focus on your studies
                        and settle into your new space with ease. Trust Prestige Moving & Delivery to make your
                        student move a stress-free and seamless process.
                    </p>
                </div>
                <img src="https://prestige-movers.s3.amazonaws.com/services-moving/Arrangements_Students.png"
                    alt="Arrangements_Students" class="order-1 3xl:order-2">
            </div>
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <img src="https://prestige-movers.s3.amazonaws.com/services-moving/Arrangements_Old.png"
                    alt="Arrangements_Old">
                <div class="flex flex-col items-center 3xl:items-start">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        Moving Arrangements for Seniors</h2>
                    <p class="text-lg text-center 3xl:text-left">At Prestige Moving & Delivery, we understand the
                        unique challenges that seniors face when it comes to moving and arranging their belongings. Our
                        senior moving and arrangements service is designed to provide personalized assistance and
                        support during this transitional phase of life. From carefully packing and organizing belongings
                        to coordinating logistics and providing emotional support, our compassionate team ensures a
                        smooth and comfortable move for seniors. With our expertise and attention to detail, we strive
                        to create a stress-free moving experience that honors the specific needs and preferences of each
                        individual. Trust Prestige Moving & Delivery to handle your senior move with care, respect,
                        and professionalism.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center px-4 md:px-0 py-12 3xl:w-4/5 mx-auto">
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto">We’re here to address your concerns!
        </h1>
        <p class="mx-auto text-lg laptop:w-10/12 text-center my-3 mb-14">It’s all but normal to be worried about
            different aspects of your move. We would like to put your mind at ease by providing information about six
            most common concerns our customers may have!

        </p>
    </div>
    <div
        class="grid grid-cols-1 px-4 laptop:px-0 md:grid-cols-2 items-start justify-items-center laptop:grid-cols-3 gap-10 mb-16 laptop:w-4/5 mx-auto">
        <div class="flex flex-col gap-4 items-center" *ngFor="let item of movingConcerns">
            <img [src]="item.img" [alt]="item.name">
            <h3 class="text-center px-2">{{item.name}}</h3>
            <p class="text-lg text-center">{{item.description}}</p>
        </div>
    </div>
</div>
<section class="pt-20 lg:pt-40 pb-40 bg-gradiant relative overflow-hidden">
    <div class="container mx-auto relative px-4 md:px-8 laptop:px-16">
        <div class="gap-8 grid grid-cols-1 3xl:grid-cols-2 items-center relative">
            <div class="text-center 3xl:text-left w-full 3xl:w-3/5 m-auto laptop:m-0 order-2 3xl:order-1"
                appIntersection #intersection="intersection" (isIntersecting)="isIntersecting($event, intersection)"
                [isContinuous]="false">
                <div *ngIf="intersection.isElementIntersecting()" [@slideInLeft]="{ value: '', params: { delay: 500 } }"
                    class="laptop:w-4/5 laptop:mx-auto 3xl:mx-0  3xl:w-full">
                    <h3 class="text-red-5 text-[32px] leading-[38.4px]">Get a quote for upcoming moving</h3>
                    <h1
                        class="text-darkNavy text-[32px] leading-[32px] md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px] 33xl:text-[64px] 33xl:leading-[64px]">
                        Need an estimate how much your move will cost?
                    </h1>
                </div>
                <a routerLink="/services/moving"
                    class="rounded-lg px-9 uppercase py-3 mt-6 text-basic-0 gap-1 bg-red-5 hover:text-white relative z-10 hover:bg-red-5 inline-flex items-center Up cursor-pointer"
                    *ngIf="intersection.isElementIntersecting()" [@fadeInUp]="{ value: '', params: { delay: 1000 } }">
                    <i class="fa-solid fa-check text-xs text-basic-0"></i>
                    Get a Quote
                </a>
            </div>
            <div class="text-center order-1 3xl:order-2 3xl:text-left m-auto w-full" appIntersection
                #intersection="intersection" (isIntersecting)="isIntersecting($event, intersection)"
                [isContinuous]="false">
                <ng-container *ngIf="display === 'TABLET' || display === 'MOBILE'">
                    <img *ngIf="intersection.isElementIntersecting()" [@slideInLeft]
                        src="https://prestige-movers.s3.amazonaws.com/home/responsive-mover.png" alt="Movers image"
                        class="m-auto w-full" style="max-width: none" />
                </ng-container>
                <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                    <img *ngIf="intersection.isElementIntersecting()" [@slideInLeft]
                        src="https://prestige-movers.s3.amazonaws.com/home/movers.png" alt="Movers image"
                        class="relative right-1/4 w-full md:w-auto md:right-full laptop:right-2/4 3xl:right-[110%]"
                        style="max-width: none" />
                </ng-container>
            </div>
        </div>
    </div>
</section>
<section class="customers pb-6">
    <div class="container mx-auto px-4 md:px-8 laptop:px-16">
        <div class="grid grid-cols-1 relative w-10/12 m-auto lg:w-full" appIntersection #intersection="intersection"
            (isIntersecting)="isIntersecting($event, intersection)" [isContinuous]="false">
            <div class="order-2" *ngIf="intersection.isElementIntersecting()" [@slideInUp]>
                <div class="mt-8 laptop:mt-0">
                    <img src="https://prestige-movers.s3.amazonaws.com/home/customer.png" alt="customer" />
                </div>
            </div>
            <div class="order-1 w-full laptop:w-4/5 3xl:w-3/5 m-auto">
                <div class="text-center">
                    <h3 class="text-red-5 text-[24px] leading-[28.8px] md:text-[32px] md:leading-[38.4px]">
                        Reviews
                    </h3>
                    <h1
                        class="text-[32px] leading-[40px] md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]">
                        We take pride in our customers’ satisfaction!
                    </h1>
                </div>
                <ng-container>
                    <div>
                        <swiper [config]="customerConfig" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()"
                            *ngIf="intersection.isElementIntersecting()"
                            [@slideInRigh]="{ value: '', params: { delay: 1000 } }">
                            <ng-template *ngFor="let testimonial of testimonials" swiperSlide>
                                <div class="slide-items" *ngIf="testimonial?.is_active">
                                    <p class="my-7 text-center text-lg text-darkNavy w-12/12">
                                        {{ testimonial?.description }}
                                    </p>
                                    <span class="text-xl block text-center text-darkNavy font-bold">
                                        {{ testimonial?.name }}
                                    </span>
                                </div>
                            </ng-template>
                        </swiper>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>