import { Component } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';

@Component({
  selector: 'app-junk-removal',
  templateUrl: './junk-removal.component.html',
  styleUrls: ['./junk-removal.component.scss']
})
export class JunkRemovalComponent {
  public display: DisplayType | null = this.displayService.display;
  constructor(private readonly displayService: DisplayService) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }
}
