import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../resource.service';
import { GenericDataResponse } from '../../../interfaces/generic-response.interface';
import { Observable } from 'rxjs';
import {
  ISubscriptionRequest,
  ISubscriptionResponse,
} from '../../../interfaces/general.interface';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends ResourceService<
  ISubscriptionResponse,
  ISubscriptionRequest
> {
  private readonly endPoint = '/subscribe';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  subscription(
    data: ISubscriptionRequest
  ): Observable<GenericDataResponse<ISubscriptionResponse>> {
    return this.post(this.endPoint, data);
  }
}
