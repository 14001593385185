import { Injectable } from '@angular/core';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class HubSpotChatService {
  loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = '//js-na1.hs-scripts.com/43578522.js';
    document.body.appendChild(script);
    window.HubSpotConversations.widget.load();
  }

  openChat() {
    if (window && window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  }

  closeChat() {
    if (window && window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
      
    }
  }
}
