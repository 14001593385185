import { Component } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';

@Component({
  selector: 'app-furniture-assembly',
  templateUrl: './furniture-assembly.component.html',
  styleUrls: ['./furniture-assembly.component.scss']
})
export class FurnitureAssemblyComponent {
  public display: DisplayType | null = this.displayService.display;
  constructor(private readonly displayService: DisplayService) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

}
