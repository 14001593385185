import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GenericDataResponse } from '../interfaces/generic-response.interface';
import { IServiceCategoryResponse } from '../interfaces/services.interface';
import { ResourceService } from './resource.service';


@Injectable({
  providedIn: 'root',
})
export class ServicesService extends ResourceService<IServiceCategoryResponse[]> {
  private readonly endPoint = '/service-categories';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getServices(): Observable<GenericDataResponse<IServiceCategoryResponse[]>> {
    return this.get(this.endPoint)
  }
}
