<header class="relative bg-white z-[9999999999] w-full h-[75px]">
  <div class="w-full h-[75px] shadow-md fixed flex items-center bg-white top-0 left-0 z-[9999999999]">
    <div class="w-full px-4 md:px-16">
      <div class="flex justify-between items-center">
        <ul class="flex flex-wrap justify-between gap-7 items-center">
          <a routerLink="/"><img class="hidden md:block" src="/assets/images/logos/logo_dark_sm.svg"
              alt="Prestige Logo" />
            <img class="block md:hidden" src="/assets/images/logos/Sign.svg" alt="Prestige Logo" />
          </a>
          <li *ngFor="let item of nabvarItems" class="hidden laptop:block">
            <a [routerLink]="item.route" [fragment]="item?.fragment" class="text-base text-darkNavy hover:text-orange">
              {{ item.name }}
            </a>
          </li>
        </ul>
        <div>
          <ul class="flex flex-wrap justify-between gap-3 items-center hidden laptop:flex">
            <li>
              <a routerLink="/services" class="rounded-lg px-6 py-[10px] bg-orange text-white text-lg hover:text-white">
                Get a Quote</a>
            </li>
            <li>
              <div *ngIf="!searchOpen" (click)="openSearchbar()" class="cursor-pointer">
                <img src="/assets/icons/Prestige-search.svg" width="32px" height="32px" alt="search icon" />
              </div>
              <label *ngIf="searchOpen" class="relative flex items-center">
                <!-- <span class="inset-y-0 left-0 flex items-center pr-2">
                    <img src="/assets/icons/search.svg" alt="search icon" />
                  </span> -->
                <input
                  class="placeholder:text-slate-500 block bg-transparent w-full border border-slate-300 rounded-md py-2 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search here" type="text" name="search" />
              </label>
            </li>
            <li>
              <a href="https://www.facebook.com/prestigemovingdelivery" target="_blank">
                <img src="/assets/icons/Prestige-facebook.svg" width="32px" height="32px" alt="Facebook Logo" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/prestigemovers.ca" target="_blank">
                <img src="/assets/icons/Prestige-instagram.svg" width="32px" height="32px" alt="Instagram Logo" />
              </a>
            </li>
          </ul>
          <ul class="flex flex-wrap justify-between gap-3 items-center flex laptop:hidden">
            <li>
              <a routerLink="/services"
                class="px-6 py-[10px] text-lg bg-transparent text-orange font-bold hover:text-orange md:bg-orange md:rounded-lg md:text-white md:hover:text-white">
                Get a Quote</a>
            </li>
            <li class="hidden md:block laptop:hidden">
              <div *ngIf="!searchOpen" (click)="openSearchbar()" class="cursor-pointer">
                <img src="/assets/icons/Prestige-search.svg" width="32px" height="32px" alt="search icon" />
              </div>
              <label *ngIf="searchOpen" class="relative flex items-center">
                <input
                  class="placeholder:text-slate-500 block bg-transparent w-full border border-slate-300 rounded-md py-2 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search here" type="text" name="search" />
              </label>
            </li>
            <li class="hidden md:block laptop:hidden">
              <a href="https://www.facebook.com/prestigemovingdelivery" target="_blank">
                <img src="/assets/icons/Prestige-facebook.svg" width="32px" height="32px" alt="Facebook Logo" />
              </a>
            </li>
            <li class="hidden md:block laptop:hidden">
              <a href="https://www.instagram.com/prestigemovers.ca" target="_blank">
                <img src="/assets/icons/Prestige-instagram.svg" width="32px" height="32px" alt="Instagram Logo" />
              </a>
            </li>
            <li>
              <div class="menu-section">
                <button class="open-menu pr-0" (click)="ToggleMenu()">
                  <img [src]="ToggleState?'assets/icons/Menu Icon.svg':'assets/icons/menu-icon.svg'" alt="Menu icon" />
                </button>
                <div class="menu" [ngClass]="{ open: ToggleState }">
                  <nav class="w-full md:w-4/5 mt-12">
                    <ul class="gap-8 grid grid-cols-1 w-full">
                      <li *ngFor="let item of nabvarItems">
                        <a [routerLink]="item.route" [fragment]="item?.fragment" class="text-basic-0 text-xl hover:text-red-5"
                          (click)="ToggleMenu()">
                          {{ item.name }}
                        </a>
                      </li>
                    </ul>
                    <ul class="flex w-full flex-wrap gap-4 items-center mt-10 md:hidden">
                      <li [ngClass]="searchOpen?'w-full':'w-auto'">
                        <div *ngIf="!searchOpen" (click)="openSearchbar()" class="cursor-pointer">
                          <img src="/assets/icons/Prestige-search-small.svg" width="30px" height="24px"
                            alt="search icon" />
                        </div>
                        <label *ngIf="searchOpen" class="relative flex items-center">
                          <input
                            class="placeholder:text-slate-500 block bg-transparent w-full border border-slate-300 rounded-md py-2 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                            placeholder="Search here" type="text" name="search" />
                        </label>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/prestigemovingdelivery" target="_blank">
                          <img src="/assets/icons/Prestige-facebook-small.svg" width="30px" height="24px"
                            alt="Facebook Logo" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/prestigemovers.ca" target="_blank">
                          <img src="/assets/icons/Prestige-instagram-small.svg" width="30px" height="24px"
                            alt="Instagram Logo" />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>