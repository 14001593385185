import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
// import {provideClientHydration} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

//modules
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './modules/pages/pages.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { StripeModule } from 'stripe-angular';

//interceptors
import { RequestHeaderInterceptor } from './core/interceptors/http-header.interceptor';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';

//components
import { AppComponent } from './app.component';
import { ToastComponent } from './shared/components/toast/toast.component';

//services
import { ErrorHandlerService } from './core/services/helper/error-handler.service';

//ng-zorro
registerLocaleData(en);
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { environment } from '../environments/environment';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';

import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  UploadOutline,
  InboxOutline,
  DownloadOutline,
  DragOutline,
} from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [
  UploadOutline,
  InboxOutline,
  DownloadOutline,
  DragOutline,
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PagesModule,
    ToastComponent,
    GoogleMapsModule,
    NzNotificationModule,
    StripeModule.forRoot(environment.STRIPE_KEY),
    NzSpinModule,
    NzIconModule.forRoot(icons),
  ],
  providers: [
    // provideClientHydration(),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
