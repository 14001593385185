import { Component, OnInit } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { BoxesSupplies } from '../../../core/consts/services.const';

@Component({
  selector: 'app-boxes-supplies',
  templateUrl: './boxes-supplies.component.html',
  styleUrls: ['./boxes-supplies.component.scss'],
})
export class BoxesSuppliesComponent implements OnInit {
  public display: DisplayType | null = this.displayService.display;
  boxesSupplies = BoxesSupplies;
  constructor(private readonly displayService: DisplayService) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit() {
  }
}
