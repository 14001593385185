<ng-container [ngSwitch]="type">
  <section *ngIf="showToast">
    <div
      *ngSwitchCase="'success'"
      class="bg-green-300 p-2 text-green-700 w-80 rounded"
    >
      <div class="flex justify-between">
        <h5>{{ title }}</h5>
        <i class="fa-solid fa-xmark" (click)="showToast = false"></i>
      </div>
      <p>{{ message }}</p>
    </div>
    <div *ngSwitchCase="'danger'" class="bg-red-4 p-2 text-red-5">
      <div class="flex justify-between">
        <h5>{{ title }}</h5>
        <i class="fa-solid fa-xmark" (click)="showToast = false"></i>
      </div>
      <p>{{ message }}</p>
    </div>
    <div *ngSwitchCase="'warning'" class="bg-yellow-3 p-2 text-yellow-500">
      <div class="flex justify-between">
        <h5>{{ title }}</h5>
        <i class="fa-solid fa-xmark" (click)="showToast = false"></i>
      </div>
      <p>{{ message }}</p>
    </div>
    <div *ngSwitchCase="'info'" class="bg-blue-300 p-2 text-blue-700">
      <div class="flex justify-between">
        <h5>{{ title }}</h5>
        <i class="fa-solid fa-xmark" (click)="showToast = false"></i>
      </div>
      <p>{{ message }}</p>
    </div>
    <div *ngSwitchDefault class="bg-basic-2 p-2 text-basic-5">
      <div class="flex justify-between">
        <h5>{{ title }}</h5>
        <i class="fa-solid fa-xmark" (click)="showToast = false"></i>
      </div>
      <p>{{ message }}</p>
    </div>
  </section>
</ng-container>
