<div class="w-full laptop:w-[95%] mx-auto overflow-hidden relative">
  <div class="h-full">
    <div
      class="bg-basic-1 flex flex-col px-4 py-0 md:py-14 relative justify-end md:fixed left-0 laptop:left-[100px] bottom-0 top-0 w-full md:w-[50%] laptop:w-[40%] 3xl:w-[35%] h-full overflow-hidden">
      <div class="flex flex-col gap-3 pt-20 md:pt-4 p-4">
        <img [src]="post?.image?.download_url" [alt]="post?.name"
          class="object-cover absolute contrast-[0.4] laptop:-z-[1] left-0 top-0 laptop:top-[75px] w-full h-full inline-block" />
        <div class="relative z-10 flex flex-col gap-3">
          <span>{{post?.published_at | date:'d MMM'}}</span>
          <h2>{{ post?.headline }}</h2>
          <p class="break-all whitespace-normal" [innerHTML]="post?.content.split('</')[0]"></p>
        </div>
      </div>
      <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
        <div
          class="mt-8 flex justify-between md:justify-around laptop:justify-between laptop:w-full 3xl:w-full 3xl:justify-between text-red-8.5">
          <button type="button" routerLink="/post-list">
            <h5>Back</h5>
          </button>
          <button type="button">
            <h5>Next</h5>
          </button>
        </div>
      </ng-container>
    </div>
    <div
      class="w-full md:w-[50%] laptop:w-[45%] 3xl:w-[55%] md:ml-[48%] laptop:ml-[47%] 3xl:ml-[42%] px-4 md:px-8 h-full flex flex-col gap-6 py-14 overflow-y-auto">
      <h4>{{ post?.name }}</h4>
      <p class="break-all whitespace-normal" [innerHTML]="sanitizedContent"></p>
      <ng-container *ngIf="display === 'TABLET' || display === 'MOBILE'">
        <div class="mt-8 flex justify-between laptop:w-full text-red-8.5">
          <button type="button" class="p-0" routerLink="/post-list">
            <h5>Back</h5>
          </button>
          <button type="button" class="p-0">
            <h5>Next</h5>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>