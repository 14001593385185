export interface InsurancePlans {
  id: number;
  img: string;
  title: string;
  description: string;
}

export const InsurancePlans: InsurancePlans[] = [
  {
    id: 0,
    img: 'https://prestige-movers.s3.amazonaws.com/insurance/Insurance_Standard.png',
    title: 'Standard Valuation Insurance',
    description: `This protection plan comes with any service you request at our company at no extra charge. Should any of your belongings sustain damage during a service provided by Prestige, we deliver the Standard Valuation Coverage of 60 cents per pound per item. This protection plan, however, does not cover substantial damages to items, such as scratches or dents. It will only cover damages that render the item a total loss or inoperable, such as a broken table leg..`,
  },
  {
    id: 1,
    img: 'https://prestige-movers.s3.amazonaws.com/insurance/Insurance_Full.png',
    title: 'Full-Value Protection',
    description: `In case you believe all of your belongings are worth more than 60 cents per pound, you could choose our Full-Value Protection plan. This coverage, which is based on your valuation of the items being moved, will cost 2% of such valuation. If you decide that all your possessions are worth $40,000, Prestige Moving & Delivery will offer you $800. This plan is also known as Full-Replacement Value. Should any articles be lost, damaged or destroyed during the move, our company will have a few options to make amends. We will either have the article replaced with another of the same value, have it repaired so that it will be in the same condition as before the damage was sustained, or issue a payout for the approximate value of the item.`,
  },
  {
    id: 2,
    img: 'https://prestige-movers.s3.amazonaws.com/insurance/Insurance_ThirdParty.png',
    title: 'Third-Party Insurance',
    description: `You can always entrust us to buy Full-Replacement Value plans for any services you request, or you can buy the insurance yourself from http://www.movinginsurance.com or http://www.bakerintl.com. This is highly recommended if your possessions are valued above $50,000, or you possess an item valued above $5000, such as a piano or an artwork; Full-Replacement insurance is much more inclusive than the standard-valuation coverage and offers you more options to be compensated for your loss.`,
  },
];

export interface Items {
  id: number;
  title: string;
  description: string;
  list: any;
}

export const Items: Items[] = [
  {
    id: 0,
    title: 'Insurance of specialty items',
    description: `The following items must be packed or crates in specialised boxes/cartons to be included in the insurance :`,
    list: [
      'Any items made of natural stones, ceramic, porcelain, or glass.',
      'All artworks, including paintings, sculptures, statues, etc.',
      'Any hanging fixtures such as mirrors, chandeliers, and wall clocks.',
      'Fragile appliances, including microwaves, printers, computers, screens and flat TVs.',
      'Sports equipment.',
      'Motorized equipment.',
    ],
  },
  {
    id: 1,
    title: 'Absence of Liability',
    description: `Prestige Moving & Delivery will not, under any circumstances, be liable for:`,
    list: [
      'Any damage to the goods described in the bill of lading caused by natural accidents or due to temperature and moisture.',
      ' Any inherent malfunctioning or fault in the goods.',
      'Any internal damage or malfunctioning in electronics unrelated to external damage caused by our crew.',
      'Any jewelry, cash, documents, checks, bills, etc., not transported by our movers or not included in the “Moving Valuables” section of our service contracts.',
      'Any damage to the items that have not been caused by our crew members.',
      'Any damage done to the items while being carried through passageways or stairways with less than 3 inches of space from all the surrounding walls, the ceiling, and the railings to the item. This will be pointed out to the client by our crew, and these items will only be moved upon their acceptance of the risk.',
      'Items packed by the owner. Prestige will only be responsible for outward damage to the boxes and cartons of such items caused by our crew that is proven to have led to the destruction of their contents.',
      'Any malfunctioning electronic items and powered equipment after the move or delivery unless there is clear evidence of improper handling of the item during the move by our crew.',
      'Damages caused by non-routine moving, including but not limited to standing pieces on end, sharp turns, over-crowded work areas, difﬁcult stairways, snags and sharp edges in work areas and doorways, handing over balconies, railings, and tight squeezes, and damage caused by weather.',
      'Any damage sustained during the move that is not reported to Prestige Moving & Delivery within seven days after the move is complete. It is the client’s responsibility to report the damage to our crew on the site and then to fill out our online claim form within no more than seven days after the move. Damages not reported to the crew members or claims filed after seven days of the incident will not be accepted.',
    ],
  },
];
