import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { GenericDataResponse } from '../interfaces/generic-response.interface';
import { ResourceService } from './resource.service';
import { IHomePageDataResponse } from '../interfaces/home.interface';


@Injectable({
  providedIn: 'root',
})
export class HomeService extends ResourceService<IHomePageDataResponse> {
  private readonly endPoint = '/home';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getHomeInfo(): Observable<GenericDataResponse<IHomePageDataResponse>> {
    return this.get(this.endPoint)
  }
}
