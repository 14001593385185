<div>
    <div class="container mx-auto md:px-8 laptop:px-16">
        <div class="min-h-[310px]">
            <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Assembly_3xl.png"
                    alt="Assembly" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'TABLET'">
                <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Assembly_medium.png"
                    alt="Assembly" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'MOBILE'">
                <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Assembly_small.png"
                    alt="Assembly" class="m-auto w-full">
            </ng-container>
        </div>
        <div class="-translate-y-1/4 laptop:-translate-y-2/4 text-center px-4 md:px-0">
            <h3 class="text-red-5 text-[32px] leading-[38.4px]">Furniture Assembly</h3>
            <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px]">Expert assembly for stress-free living
            </h1>
            <p class="mx-auto text-center text-lg 3xl:w-3/5">At Prestige Moving & Delivery, when we say we do it all, we mean it. Our
                extensive list of services includes all kinds of furniture assembly, big or small at reasonable rates
                with the help of a friendly and professional crew. We promise our customers a service with no delays and
                with competitive market rates.
            </p>
        </div>
        <div class="mx-auto w-[95%] 3xl:w-4/5 bg-light1 px-6 py-10 md:p-10 laptop:py-14 mb-10">
            <h3 class="text-red-5 text-[32px] leading-[38.4px] text-center">Our Process</h3>
            <h1
                class="text-darkNavy text-[40px] leading-10 md:text-[64px] text-center md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]">
                Your perfect space is just a few clicks away.
            </h1>
            <div class="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-4 gap-12 my-8">
                <div class="flex flex-col items-center">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Icons_request.png"
                        alt="Icons_request">
                    <h5 class="text-center">Submit request online</h5>
                </div>
                <div class="flex flex-col items-center">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Icons_confirmation.png"
                        alt="Icons_confirmation">
                    <h5 class="text-center">Receive Confirmation</h5>
                </div>
                <div class="flex flex-col items-center">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Icons_arrive.png"
                        alt="Icons_arrive">
                    <h5 class="text-center">Our team arrives on time</h5>
                </div>
                <div class="flex flex-col items-center">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Icons_client.png"
                        alt="Icons_client">
                    <h5 class="text-center">Another happy client</h5>
                </div>
            </div>

            <div class=" md:w-4/5 laptop:w-4/5 mx-auto text-center">
                <p class="my-16 text-lg">Our furniture assembly service is top-notch, with years of experience putting
                    together a wide variety of furniture styles and models. We use high-quality tools and equipment to
                    ensure that each piece is assembled correctly and securely. We also offer a free quote for our
                    assembly service which will give you an accurate estimate for the cost of your request!</p>
                <button routerLink="/services/assembling" class="md:w-auto text-center uppercase bg-red-5 text-basic-0">Book
                    Now</button>
            </div>
        </div>

        <div class="mx-auto laptop:w-4/5 px-4 laptop:px-0">
            <div class="my-8 mt-16">
                <h3 class="text-red-5 text-center text-[32px] leading-[38.4px]">Sit back and relax, let us handle the
                    assembly!
                </h3>
                <h1 class="text-darkNavy text-center text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
                3xl:text-[64px] 3xl:leading-[64px]">Our Furniture Assembly Service Includes:
                </h1>
            </div>
            <div class="grid grid-cols-1 gap-y-12">
                <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Flatpack.png"
                        alt="Flatpack">
                    <div>
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Flatpack Furniture Assembly</h2>
                        <p class="text-lg md:text-center 3xl:text-left">We assemble all kinds of furniture from
                            leading suppliers such as Ikea, Wayfair, Costco, Structube, Surplus Furniture and many
                            others. It's not easy to assemble different parts of any furniture without proper tools and
                            with hundreds of screws! That is where Prestige will come to your aid!
                        </p>
                    </div>
                </div>
                <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center">
                    <div class="order-2 3xl:order-1">
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Home Furniture Assembly</h2>
                        <p class="text-lg md:text-center 3xl:text-left">When you buy furniture for your house, one of
                            the main characteristics that you look for is durability. Furniture is expensive and you
                            want it assembled just right to avoid the fear of breaking or damaging it during assembly.
                            At Prestige , we are equipped for those big bulky home furniture including Murphy beds,
                            custom wardrobes, complex bookshelves and also bed frames.
                        </p>
                    </div>
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Home.png" alt="Home"
                        class="order-1 3xl:order-2">
                </div>

                <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center">
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Office.png"
                        alt="Office">
                    <div>
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Office Furniture Assembly</h2>
                        <p class="text-lg md:text-center 3xl:text-left">Office furniture, more than anything, needs
                            to be sturdy enough to withstand all the work trauma. Offices are set up for the long haul,
                            and having correctly assembled furniture is essential. You can't go up and replace the
                            furniture every other month, that is why you need to hire a furniture assembly service to to
                            get it all right the very first try.</p>
                    </div>
                </div>
                <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center">
                    <div class="order-2 3xl:order-1">
                        <h2
                            class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                            Outdoor Furniture Assembly</h2>
                        <p class="text-lg md:text-center 3xl:text-left">Our professional assemblers have wide
                            expertise and years of experience in constructing your furniture quickly and per the
                            manufacturer's specifications; and that includes all types of outdoor furniture! Whether you
                            are a home or business owner, we are here to provide you with our expertise in assembling
                            picnic tables, lounge furniture, basketball hoops, trampoline, and so on!
                        </p>
                    </div>
                    <img src="https://prestige-movers.s3.amazonaws.com/services-furniture-assembly/Outdoor.png"
                        alt="Outdoor" class="order-1 3xl:order-2">
                </div>
            </div>
        </div>

    </div>
    <div class="bg-dun-4 p-8 mt-16 text-center">
        <h2 class="text-red-5">D.I.Y Warning!</h2>
        <p class="mb-8 mt-4 md:px-16 text-lg mx-auto laptop:w-4/5">Incorrect assembly can create safety hazards,
             shorten the life-span of your
            product and/or result in
            some or total loss of product functionality. Assembly can be dangerous. Always utilize the proper
            equipment and help. Consider hiring trained, experienced and insured professionals if you cannot acquire
            all components for a safe assembly.
        </p>
    </div>
</div>