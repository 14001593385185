import { ErrorHandler, Injectable } from '@angular/core';
import { NotificationHandlerService } from './notification-handler.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private notificationHandlerService: NotificationHandlerService) {}

  handleError(error: any): void {
    console.error(error);
    // this.notificationHandlerService.showNotification(
    //   'error',
    //   'Error',
    //   error.message
    // );
  }
}
