<section class="bg-gray-5 py-20">
  <div class="container mx-auto px-4 md:px-8 laptop:px-16">
    <div class="text-center md:mx-auto">
      <h1 class="text-basic-0 text-[40px] leading-10 md:text-[64px] md:leading-[64px]">
        {{ title }}
      </h1>
      <p class="text-center mt-5 text-laptop text-slate-300">
        {{ description }} </p>
      <form [formGroup]="subscriptionForm" (ngSubmit)="onSubscribeSubmit(subscriptionForm)" class="my-10">
        <ng-container *ngIf="
            display === 'TABLET' ||
            display === 'LAPTOP' ||
            display === 'DESKTOP'
          ">
          <div
            class="bg-darkNavy flex justify-between items-center rounded-lg md:w-8/12 laptop:w-5/12 m-auto overflow-hidden pl-5">
            <input type="email" placeholder="Enter your email address" formControlName="email"
              class="!bg-transparent border-none placeholder:!text-slate-400 w-full focus:ring-0 focus:!text-slate-400" />
            <button type="submit"
              class="rounded-lg px-5 py-3 bg-red-5 text-basic-0 hover:bg-red-5 duration-300 inline-flex items-center">
              Subscribe
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
          <div class="flex flex-col justify-center items-center w-full m-auto overflow-hidden">
            <input type="email" placeholder="Enter your email address" formControlName="email"
              class="!bg-darkNavy w-full rounded-lg p-4 h-[46px] m-auto border-none placeholder:text-center placeholder:!text-slate-400 focus:ring-0 focus:!text-slate-400" />
            <button type="submit"
              class="rounded-lg px-5 mt-5 py-3 bg-red-5 text-basic-0 hover:bg-red-5 duration-300 inline-flex items-center">
              Subscribe
            </button>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</section>