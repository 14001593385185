<section>
    <div class="container mx-auto md:px-8 laptop:px-16">
        <div class="min-h-[310px]">
            <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                <img src="https://prestige-movers.s3.amazonaws.com/insurance/Insurance_3xl.png" alt="insurance"
                    class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'TABLET'">
                <img src="https://prestige-movers.s3.amazonaws.com/insurance/Insurance_medium.png" alt="insurance"
                    class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'MOBILE'">
                <img src="https://prestige-movers.s3.amazonaws.com/insurance/Insurance_small.png" alt="insurance"
                    class="m-auto w-full">
            </ng-container>
        </div>
        <div class="-translate-y-1/4 laptop:-translate-y-2/4 text-center px-4 md:px-0">
            <h3 class="text-red-5 text-[32px] leading-[38.4px]">Insurance Coverage, Plans and Terms</h3>
            <h1 class="text-gray-7 text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px]">We are fully insured for your protection!
            </h1>
        </div>
    </div>
</section>
<section class="laptop:w-4/5 3xl:w-3/5 mx-auto">
    <div class="container mx-auto px-4 md:px-8 laptop:px-16">
        <div>
            <div>
                <p class="text-center text-lg text-gray-7">Prestige Moving & Delivery carries $2,000,000 General
                    Liability
                    coverage, which covers bodily
                    injuries and property damages. In addition, our flatbed trucks carry $40,000 cargo insurance, and
                    both insurance limits can be increased to a higher amount if required by our clients.
                    Our crew members are insured by WSIB; under the Workplace Safety and Insurance Act, our employees
                    are covered for loss of earnings, for benefits and for healthcare if an injury or illness occurs at
                    work.
                    All the staff at Prestige Moving & Delivery take it upon themselves to see to the safety of our
                    customers’ belongings throughout all moving, delivery and shipping services. However, despite our
                    best efforts to safeguard our customers’ property and possessions, accidents may happen. Prestige
                    offers various insurance options to cover any possible damage or loss.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="laptop:w-4/5 3xl:w-3/5 mx-auto my-10">
    <div class="container mx-auto px-4 md:px-8 laptop:px-16">
        <h1
            class="text-gray-7 my-10 mt-14 md:w-[60%] laptop:w-[70%] text-[40px] leading-10 md:text-[64px] md:leading-[64px] mx-auto laptop:mx-0 text-center laptop:text-left">
            Insurance Plans </h1>
        <div class="mt-3 gap-y-8 grid grid-cols-1">
            <div *ngFor="let type of InsurancePlans">
                <div class="flex items-center laptop:items-start flex-col laptop:flex-row ">
                    <img class="w-[150px] mx-auto" [src]="type.img" alt="insurance-plan">
                    <div class="text-sm text-gray-7 md:pl-3">
                        <h2
                            class="text-gray-7 text-[32px] leading-[38.4px] md:text-[40px] md:leading-10 text-center laptop:text-left my-4">
                            {{ type.title }}
                        </h2>
                        <p class="text-lg-5 text-lg text-justify md:text-left">{{ type.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-12 gap-y-10 grid grid-cols-1 w-full mt-14">
            <div *ngFor="let type of Items">
                <div class="text-sm text-gray-7 pl-3 ">
                    <h2 class="text-center md:text-left">
                        {{ type.title }}
                    </h2>
                    <p class="text-lg-5 text-lg my-5 !text-gray-7">{{ type.description }}</p>
                    <ul class="list-disc pl-6">
                        <li class="text-lg leading-[22.63px]" *ngFor="let item of type.list">
                            <p>{{item}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bg-[#DED1BE] py-20">
    <div class="container mx-auto px-4 md:px-8 laptop:px-16">
        <div class="text-center w-full md:w-9/12 laptop:w-4/5 m-auto">
            <h1 class="text-[#10142F] text-[40px] leading-10 laptop:text-[64px] laptop:leading-[64px]">
                Our Comprehensive Work-Safety Policies and Insurance Coverage
            </h1>
            <p class="text-center my-6 text-laptop text-gray-7">
                Empowering You with Insurance Plan Resources: Downloadable Insurance Plan Information
            </p>
            <a href="https://prestige-movers.s3.amazonaws.com/insurance/WSIBClearanceCertificate.pdf" target="_blank">
                <button
                    class="rounded-lg text-basic-0 px-5 py-3 bg-red-5 text-lg-0 hover:bg-red-5 duration-300 inline-flex items-center uppercase">
                    Download WSIB Clearance Certificate</button>
            </a>
        </div>
    </div>
</section>