export const environment = {
  STRIPE_KEY:'pk_test_51K1ns2Ltl3Rfh6WAbqOCM2CiwoWVyUc6u2nb06cSoSHMJalNDiKycryUJ6BcYBfpj7vB4tulG2VLCLTUSVkTkx7000ki7N7PU6',
  API_KEY : 'AIzaSyAIhgULi-bsmsovi0hVmcxLJuVjJ835Ao4',
  production: false,
  url:'https://prestige-api.sunshineteam.ca/api',
  site:'http://localhost:4200/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
