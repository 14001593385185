<div *ngIf="loading">
  <div
    class="loader"
  ></div>
  <nz-spin
    class="absolute top-1/2 left-1/2 mr-[-50%] z-[200]"
    [nzSize]="'large'"
  >
  </nz-spin>
</div>
<div class="min-h-screen overflow-x-hidden" [ngClass]="{ 'loader': loading }">
  <router-outlet></router-outlet>
</div>
