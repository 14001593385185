<div class="w-full md:w-[70%] laptop:w-2/3 3xl:w-1/2 mx-auto pt-11 md:pt-44 laptop:pt-[100px] px-[22px] mb-14 min-h-[70vh]">
    <h2 class="text-gray-7 text-center laptop:text-[64px] laptop:leading-[64px] 3xl:text-[64px] 3xl:leading-[64px]">
        Lorem ips amet consectetur.
    </h2>
    <p class="text-gray-5 mt-2 text-center text-xl">
        Lorem ipsum dolor sit amet consectetur. Lacus interdum egestas nibh sed ornare ipsum vestibulum lectus.Lorem
        ipsum dolor sit amet consectetur. Lacus interdum egestas nibh sed ornare ipsum vestibulum lectus.Lorem ipsum
        dolor sit amet consectetur. Lacus interdum egestas nibh sed ornare ipsum vestibulum lectus.
    </p>

    <div class="mt-[72px]">
        <p class="text-center text-gray-7 text-lg font-bold leading-[22px] mb-4">
            Lorem ipsum
        </p>
        <div class="flex justify-center">
            <h2 class="bg-dun-2 text-center text-gray-7 py-6 w-[400px] rounded-[10px] px-4 cursor-pointer" #buttonToCopy
                (click)="copyText(buttonToCopy.innerText)">
                {{ code }}
                <ng-container *ngIf="!isCopied">
                    <i class="fa-solid fa-copy text-dun-5 text-[25px] float-right mt-1"></i>
                </ng-container>
                <ng-container *ngIf="isCopied">
                    <i class="fa-solid fa-check text-dun-5 text-[25px] float-right mt-1"></i>
                </ng-container>
            </h2>
        </div>
    </div>
</div>