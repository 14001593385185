<section>
    <div class="container mx-auto md:px-8 laptop:px-16">
        <div class="min-h-[310px]">
            <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                <img src="https://prestige-movers.s3.amazonaws.com/privacy-policy/PrivacyPolicy_3xl.png" alt="privacy-policy" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'TABLET'">
                <img src="https://prestige-movers.s3.amazonaws.com/privacy-policy/PrivacyPolicy_medium.png" alt="privacy-policy" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'MOBILE'">
                <img src="https://prestige-movers.s3.amazonaws.com/privacy-policy/PrivacyPolicy_small.png" alt="privacy-policy" class="m-auto w-full">
            </ng-container>
        </div>
        <div class="-translate-y-2/4 laptop:-translate-y-2/4 text-center px-4 md:px-0">
            <h3 class="text-red-5 text-[32px] leading-[38.4px]">Terms and Policies</h3>
            <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px]">Your Guide to Transparent Engagement
        </h1>
        </div>
    </div>
</section>
<section>
    <div class="container mx-auto px-4 md:px-8 laptop:px-8 laptop:w-4/5">
        <div class="gap-8 grid grid-cols-1 laptop:grid-cols-4">
            <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                <div>
                    <span class="text-lg font-bold text-gray-5 leading-[22px] uppercase">categories</span>
                    <div class="accordion my-1">
                        <div *ngFor="let type of privacyPolicy">
                            <button type="button" (click)="getItems(type.name)"
                                class="w-full flex items-center text-left text-sm font-bold px-0" [ngClass]="
                                type.isActive === true
                                  ? 'text-red-5'
                                  : 'text-gray-5'
                              ">
                                {{ type.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="laptop:col-span-3">
                <div class="grid grid-cols-1 laptop:grid-cols-2 items-center w-full md:w-3/5 laptop:w-4/5 m-auto laptop:w-full">
                    <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                        <div></div>
                    </ng-container>
                    <div class="relative">
                        <button class="absolute left-0 px-2 py-0 top-2/4 -translate-y-2/4"><svg width="24" height="25"
                                viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.5 14.5H14.71L14.43 14.23C15.4439 13.054 16.0011 11.5527 16 10C16 8.71442 15.6188 7.45772 14.9046 6.3888C14.1903 5.31988 13.1752 4.48676 11.9874 3.99479C10.7997 3.50282 9.49279 3.37409 8.23192 3.6249C6.97104 3.8757 5.81285 4.49477 4.90381 5.40381C3.99477 6.31285 3.3757 7.47104 3.1249 8.73192C2.87409 9.99279 3.00282 11.2997 3.49479 12.4874C3.98676 13.6752 4.81988 14.6903 5.8888 15.4046C6.95772 16.1188 8.21442 16.5 9.5 16.5C11.11 16.5 12.59 15.91 13.73 14.93L14 15.21V16L19 20.99L20.49 19.5L15.5 14.5ZM9.5 14.5C7.01 14.5 5 12.49 5 10C5 7.51 7.01 5.5 9.5 5.5C11.99 5.5 14 7.51 14 10C14 12.49 11.99 14.5 9.5 14.5Z"
                                    fill="#A6A9B4" />
                            </svg>
                        </button>
                        <input class="w-full !pl-[35px]" type="text" name="unitLetter" placeholder="Search  here"
                            (input)="onSearch($event)" (keyup)="onSearch($event)" />
                    </div>
                    <ng-container *ngIf="display === 'TABLET' || display === 'MOBILE'">
                        <div class="accordion my-1">
                            <nz-select [(ngModel)]="categoryName" (ngModelChange)="getItems($event)">
                                <nz-option *ngFor="let type of privacyPolicy" [nzValue]="type.name"
                                    [nzLabel]="type.name"></nz-option>
                            </nz-select>
                        </div>
                    </ng-container>
                </div>
                <div class="mt-3 p-5 bg-light1 gap-y-8 grid grid-cols-1">
                    <div *ngFor="let type of Items">
                        <h4 class="text-gray-5">
                            {{ type.name }}
                        </h4>
                        <div class="text-sm text-gray-5 p-2 pb-3">
                            <p class="text-basic-5 text-lg">{{ type.content }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>