import { Component } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss'],
})
export class StorageComponent {
  public display: DisplayType | null = this.displayService.display;
  storage: any;
  constructor(
    private readonly displayService: DisplayService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  getStorageType(type: string) {
    const storage = {
      ...this.storage,
      details: {
        ...this.storage?.details,
        storage_type: type,
      },
    };
    localStorage.setItem('storage', JSON.stringify(storage));
    if (type === 'pick_up') {
      this.router.navigate(['/services/storage/personal-info'], {
        relativeTo: this.route,
      });
    } else if (type === 'drop_off') {
      this.router.navigate(['/services/storage/drop-off-request'], {
        relativeTo: this.route,
      });
    }
  }
}
