<div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
        <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-delivery/Delivery_3xl.png" alt="Delivery"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'TABLET'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-delivery/Delivery_medium.png" alt="Delivery"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-delivery/Delivery_small.png" alt="Delivery"
                class="m-auto w-full ">
        </ng-container>
    </div>
    <div class="-translate-y-6 text-center px-4 md:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">Prestige Delivery </h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">We Take Care Of All Your Delivery Needs 
        </h1>
        <p class="mx-auto text-lg 3xl:w-9/12 text-center my-3 mb-14">Looking for a reliable delivery service that
            can handle all of your delivery needs? Look no further. With a variety of vehicle types in our fleet, we can
            deliver everything from small items like food and groceries to larger items like furniture and appliances.
            Our fleet includes sedan vehicles, pickup trucks, cargo vans, cube vans, and flatbed trucks to ensure that
            we can handle any size or type of delivery. We offer rush, same-day, and next-day delivery services to meet
            your specific needs, and our 24/7 dispatch team is always just a phone call away. Trust us to deliver your
            packages and items safely and efficiently, so you can focus on what matters most.
        </p>
        <button routerLink="/services/delivery" class="mx-auto text-center uppercase bg-red-5 text-basic-0">Book
            now</button>
    </div>
    <div class="text-center px-4 md:px-0 laptop:w-10/12 mx-auto my-8 mt-12">
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">We’ll pick up and deliver for you
        </h1>
        <p class="mx-auto text-lg 3xl:w-10/12 text-center my-3 mb-14">Furniture You Purchased From KIJIJI, Store
            pickups, Prescription Pickup, Grocery Pickup, Business Pickup, Direct Delivery, Skid Drop Off
        </p>
    </div>
    <ng-container *ngIf="!show">
        <div
            class="md:w-4/5 laptop:w-10/12 my-14 mx-auto grid grid-cols-1 md:grid-cols-3 laptop:grid-cols-4 3xl:grid-cols-5 gap-8 items-start pt-4 mb-28 px-4">
            <div *ngFor="let vt of vehicleTypes" (click)="selectVehicle(vt.id)"
                class="rounded-[10px] cursor-pointer p-2 md:p-3 md:pb-4 flex flex-row md:flex-col gap-3 md:gap-2 items-center justify-evenly md:justify-center"
                [ngClass]="
              vt.isSelect
                ? 'bg-dun-3 border border-dun-5'
                : 'bg-dun-1 border-transparent'
            ">
                <img [src]="vt.image_url" [alt]="vt.name" />
                <div class="flex flex-col gap-3 items-center justify-center">
                    <h5>{{ vt.name }}</h5>
                    <p class="text-lg">{{ vt.capacity }}</p>
                    <button class="border-b p-0 rounded-none text-gray-5 border-gray-5" (click)="viewDetails(vt.id)">
                        Read More
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="show">
        <div
            class="bg-dun-2 p-6 border border-dun-5 md:px-10 w-11/12 laptop:px-20 md:w-4/5 laptop:w-10/12 my-14  mx-auto rounded-lg flex flex-col md:flex-row items-end md:items-start gap-5">
            <div class="order-2 md:order-1">
                <div class="border-b border-gray-7 flex flex-row w-full pb-3">
                    <h5 class="text-gray-7 mr-12">{{vehicleDetails.name}}</h5>
                    <h5 class="text-gray-7">{{vehicleDetails.volume}}</h5>
                </div>
                <p class="text-gray-7 mt-5">A sedan car can typically handle small to medium-sized deliveries that do
                    not require
                    a large amount of cargo space. Some examples of deliveries that can be made with a sedan car
                    include:
                </p>
                <ul class="my-8 grid grid-cols-1 gap-4">
                    <li class="flex flex-col md:flex-row w-full">
                        <p class=" mr-2 laptop:basis-1/6 font-bold">Documents and small packages</p>
                        <p class="font-normal mt-4 md:mt-0"> Sedan cars are well-suited for delivering documents, small
                            packages, and envelopes.</p>
                    </li>
                    <li class="flex flex-col md:flex-row w-full">
                        <p class=" mr-2 laptop:basis-1/6 font-bold">Food and catering </p>
                        <p class="font-normal mt-4 md:mt-0">Sedan cars can be used for food and catering deliveries,
                            especially for small orders or short distances.</p>
                    </li>
                    <li class="flex flex-col md:flex-row w-full">
                        <p class="mr-2 laptop:basis-1/6 font-bold">Flowers and gifts</p>
                        <p class="font-normal mt-4 md:mt-0">Sedan cars are also suitable for delivering flowers and
                            small
                            gifts.</p>
                    </li>
                    <li class="flex flex-col md:flex-row w-full">
                        <p class="mr-2 laptop:basis-1/6 font-bold">Clothing and accessories</p>
                        <p class="font-normal mt-4 md:mt-0">Sedan cars can handle clothing and accessories deliveries,
                            such
                            as picking up or dropping off a dress or suit from a dry cleaner.</p>
                    </li>
                    <li class="flex flex-col md:flex-row w-full">
                        <p class="mr-2 laptop:basis-1/6 font-bold">Small household item</p>
                        <p class="font-normal mt-4 md:mt-0">Sedan cars can be used for delivering small household items
                            such
                            as electronics, small furniture, or kitchen appliances.</p>
                    </li>
                </ul>
                <p class="text-gray-7">It's important to note that the size and weight of the items being delivered
                    should
                    not exceed the cargo capacity of the sedan car, and the items should be properly packaged to ensure
                    their safety during transportation. Additionally, it's essential to comply with local laws and
                    regulations regarding the transport of goods, such as obtaining necessary permits or licenses.
                </p>
            </div>
            <button
                class="order-1 md:order-2 rounded-full p-0 inline-block !w-[34px] !h-[34px] md:!w-[140px] laptop:!w-[74px] bg-basic-0 border border-basic-5 text-basic-5"
                (click)="show=!show"><i class="fa-solid fa-xmark"></i></button>
        </div>
    </ng-container>
    <div class="text-center px-4 md:px-0 3xl:w-11/12 mx-auto my-8">
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">Delivery Solution for your business
        </h1>
        <p class="mx-auto text-lg 3xl:w-9/12 text-center my-3 mb-14">We provide a comprehensive delivery solution for
            businesses of any size. We acknowledge that prompt delivery is a crucial aspect of running a thriving
            business, and our delivery service is designed to simplify and streamline the process for you. With our
            delivery solution, scheduling pickups and tracking deliveries in real-time is effortless, ensuring that your
            customers receive their orders promptly. Our team of trained delivery professionals is committed to
            providing exceptional service, enabling you to concentrate on expanding your business. We offer flexible
            delivery options, such as same-day, next-day, and weekend delivery, to meet your specific needs. Allow us to
            handle your delivery requirements, so that you can focus on what you do best - managing your business.

        </p>
        <button routerLink="/delivery-form" class="mx-auto text-center uppercase bg-red-5 text-basic-0">Open a Corporate
            Account</button>
    </div>
    <div class="my-28">
        <ng-container *ngIf="display === 'TABLET' || display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-delivery/Website_Delivery.png"
                alt="Website_Delivery" class="m-auto">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-delivery/Website_Delivery_small.png"
                alt="Website_Delivery" class="m-auto w-11/12">
        </ng-container>
    </div>
</div>
<div class="bg-dun-2 py-16">
    <div class="w-11/12 md:w-10/12 mx-auto flex flex-col 3xl:grid 3xl:grid-cols-3 gap-12 items-center">
        <img src="https://prestige-movers.s3.amazonaws.com/services-delivery/Signup.png" alt="Signup"
            class="order-2 3xl:order-1">
        <div class="text-center px-4 md:px-0 3xl:col-span-2 order-1 3xl:order-2">
            <h3 class="text-red-5 text-[32px] leading-[38.4px]">Drive With Us</h3>
            <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
                3xl:text-[64px] 3xl:leading-[64px] mx-auto">Join Prestige Delivery Today
            </h1>
            <p class="mx-auto text-lg text-center my-3 mb-14">If you are a vehicle owner or a commercial owner or
                operator
                looking for more job opportunities, you may want to consider signing up with Prestige
                Moving & Delivery. As a contractor driver for this reputable company, you will have access to a steady
                stream of delivery and job assignments, which can help you grow your business and increase your income.
                Whether you have a small car, a van, or a larger truck, there are always opportunities available that
                match your vehicle's specifications and your skills as a driver. By joining Prestige Moving & Delivery
                as a contractor driver, you can benefit from a reliable and professional partner that will help you
                achieve your career goals and ensure your success as an independent operator in the transportation and
                logistics industry.
            </p>
            <button routerLink="/delivery-form" class="mx-auto text-center uppercase bg-red-5 text-basic-0">Sign up
                now</button>
        </div>
    </div>
</div>
<app-subscription-form [title]="'Drive with Prestige!'" [type]="'delivery'"
    [description]="'Enter your email address below and stay tuned for all the new driver positions here at Prestige!'"></app-subscription-form>