import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
@Directive({
  selector: '[appIntersection]',
  exportAs: 'intersection',
})
export class IntersectionDirective implements OnInit, OnDestroy {
  @Input() root: HTMLElement | null = null;
  @Input() rootMargin = '0px 0px 0px 0px';
  @Input() threshold = 0;
  @Input() debounceTime = 0;
  @Input() isContinuous = false;

  @Output() isIntersecting = new EventEmitter<boolean>();

  private intersectionObserver: IntersectionObserver;
  private _isIntersecting = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.createIntersectionObserver();
    this.observeElement();
  }

  ngOnDestroy() {
    this.disconnectObserver();
  }

  get isIntersectingValue(): boolean {
    return this._isIntersecting;
  }

  isElementIntersecting(): boolean {
    return this._isIntersecting;
  }

  private createIntersectionObserver() {
    const options: IntersectionObserverInit = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      this.updateIntersectionStatus(isIntersecting);

      if (isIntersecting && !this.isContinuous) {
        this.disconnectObserver();
      }
    }, options);
  }

  private observeElement() {
    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  private updateIntersectionStatus(isIntersecting: boolean) {
    if (this._isIntersecting !== isIntersecting) {
      this._isIntersecting = isIntersecting;
      this.isIntersecting.emit(isIntersecting);
    }
  }

  private disconnectObserver() {
    this.intersectionObserver.disconnect();
  }

}
