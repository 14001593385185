import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenManagementService } from '../services/auth/token-managment.service';

@Injectable()
export class RequestHeaderInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenManagementService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.tokenService.hasToken()) {
      const token = this.tokenService.getToken().toString();
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
        // setHeaders: {
        //   Authorization: `Bearer  ${this.tokenManagementService
        //     .getToken()
        //     .toString()}`,
        //   'cache-control': 'max-age:600',
        //   'Content-Type': 'application/json',
        //   Accept: 'application/json',
        //   charset: 'utf-8',
        // },
      });
    }
    return next.handle(request);
  }
}
