<div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[315px]">
        <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/BoxesandSupplies_3xl.png"
                alt="BoxesandSupplies" class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'TABLET'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/BoxesandSupplies_medium.png"
                alt="BoxesandSupplies" class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/BoxesandSupplies_small.png"
                alt="BoxesandSupplies" class="m-auto w-full">
        </ng-container>
    </div>
    <div class="-translate-y-6 text-center px-4 md:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">Packing supplies</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">Quality Products, Great Prices, Fast Shipping
        </h1>
        <p class="mx-auto text-lg 3xl:w-8/12 text-center my-3 mb-14">Prestige Moving & Delivery provides exceptional
            service, high-quality packing materials, and sturdy moving boxes to assist you in preparing, wrapping, and
            safeguarding your possessions for your upcoming move. When you have the appropriate moving supplies within
            easy reach, packing becomes a breeze. Our extensive inventory of products is available online, ranging from
            tapes and labels to complete moving kits, moving boxes, and plastic bins, making it simple to find
            everything you need in one convenient location.</p>
    </div>
    <div class="text-center px-4 md:px-0 py-14 laptop:w-4/5 mx-auto">
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-10/12">Seamless Solutions for Your Packaging Needs
        </h1>
        <p class="mx-auto text-lg laptop:w-8/12 text-center my-3 mb-10">Over 100 SKUS!</p>
    </div>
    <div
        class="grid grid-cols-1 md:grid-cols-2 items-start justify-items-center laptop:grid-cols-3 gap-10 mb-16 3xl:w-4/5 mx-auto">
        <div class="flex flex-col gap-7 items-center" *ngFor="let boxe of boxesSupplies">
            <img [src]="boxe.img" [alt]="boxe.name">
            <h2 class="text-center px-2">{{boxe.name}}</h2>
        </div>
    </div>
</div>