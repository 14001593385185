import { Component } from '@angular/core';
import { DisplayType } from '../../../../core/types/display.type';
import { DisplayService } from '../../../../shared/services/display.service';
import { BlogService } from '../../../../core/services/general/blog/blog-post.service';
import { BlogCategoryService } from '../../../../core/services/general/blog/blog-category.service';
import {
  IBlogCategoryResponse,
  IBlogResponse,
} from '../../../../core/interfaces/blog.interface';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss'],
})
export class PostListComponent {
  public display: DisplayType | null = this.displayService.display;
  current = 1;
  array = [1, 2, 3, 4];
  categoryId: number = 1;
  categories: IBlogCategoryResponse[];
  posts: IBlogResponse[];
  paginationInfo;
  paginationInfo1;
  Featured:IBlogResponse[];
  RecentPosts:IBlogResponse[];
  currentPage = 1;
  constructor(
    private readonly displayService: DisplayService,
    private blogService: BlogService,
    private blogCategoryService: BlogCategoryService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.getBlogCategory();
    this.getPostList();
  }

  getPostList(pageIndex?) {
    const params = pageIndex !== undefined ? `page=${pageIndex}` : '';
    this.blogService.getPostList(params).subscribe((res) => {
      // if (res.status === 200 ||res.status === 201) {
      this.posts = res.response;

      this.Featured = res.response.filter((n) => {
        return n.is_featured === true;
      });

      this.RecentPosts=res.response.slice(0, 5);

      if (res.meta.current_page === 1) this.paginationInfo = res.meta;
    });
  }

  getBlogCategory() {
    this.blogCategoryService.getBlogCategory().subscribe((res) => {
      // if (res.status === 200) {
      this.categories = res.response;
      // }
    });
  }

  getPostbyCtegoryId(id: number | null) {
    if (id === null) {
      this.posts = [];
      this.categoryId = null;
      return;
    }
    const categoryId = `categoryId=${id}`;
    this.blogService.searchPost(categoryId).subscribe((res) => {
      // if (res.status === 200) {
      this.posts = res.response;
      // }
      this.categoryId = id;
    });
  }

  onSearch(searchQ: string) {
    const searchQuery = searchQ.trim();
    if (!searchQuery) {
      this.getPostbyCtegoryId(this.categoryId);
    } else {
      const params = `query=${searchQuery}&categoryId=${this.categoryId}`;
      this.blogService.searchPost(params).subscribe((res) => {
        // if (res.status === 200) {
        this.posts = res.response;
        // }
      });
    }
  }

  getNextPageData(pageIndex: number) {
    this.getPostList(pageIndex);
  }
}
