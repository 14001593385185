import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//modules
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../../shared/shared.module';

//ng-zorro
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';

//components
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { FurnitureAssemblyComponent } from './furniture-assembly/furniture-assembly.component';
import { JobListComponent } from './jobs/job-list/job-list.component';
import { JobDetailComponent } from './jobs/job-detail/job-detail.component';
import { ResourcesComponent } from './resources/resources.component';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { PostListComponent } from './blog/post-list/post-list.component';
import { PostDetailComponent } from './blog/post-detail/post-detail.component';
import { TruckingComponent } from './trucking/trucking.component';
import { JunkRemovalComponent } from './junk-removal/junk-removal.component';
import { BoxesSuppliesComponent } from './boxes-supplies/boxes-supplies.component';
import { PackingServiceComponent } from './packing-service/packing-service.component';
import { StorageComponent } from './storage/storage.component';
import { MovingComponent } from './moving/moving.component';
import { ShippingCourierComponent } from './shipping-courier/shipping-courier.component';
import { SubscriptionFormComponent } from '../../shared/components/subscription-form/subscription-form.component';
import { DeliveryServiceComponent } from './delivery/delivery-service/delivery-service.component';
import { DeliveryFormComponent } from './delivery/delivery-form/delivery-form.component';
import { FinalComponent } from './delivery/final/final.component';

import { NzTypographyModule } from 'ng-zorro-antd/typography';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'careers', component: CareersComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'insurance', component: InsuranceComponent },
      { path: 'furniture-assembly', component: FurnitureAssemblyComponent },
      { path: 'jobs', component: JobListComponent },
      { path: 'job-detail/:id', component: JobDetailComponent },
      { path: 'resources', component: ResourcesComponent },
      { path: 'post-list', component: PostListComponent },
      { path: 'post-detail/:id/:slug', component: PostDetailComponent },
      { path: 'trucking', component: TruckingComponent },
      { path: 'boxes-supplies', component: BoxesSuppliesComponent },
      { path: 'packing-service', component: PackingServiceComponent },
      { path: 'junk-removal', component: JunkRemovalComponent },
      { path: 'storage', component: StorageComponent },
      { path: 'moving', component: MovingComponent },
      { path: 'delivery-service', component: DeliveryServiceComponent },
      { path: 'delivery-form', component: DeliveryFormComponent },
      { path: 'delivery-final', component: FinalComponent },
      { path: 'shipping-courier', component: ShippingCourierComponent },
    ],
  },
];

@NgModule({
  declarations: [
    HomeComponent,
    CareersComponent,
    ContactUsComponent,
    AboutUsComponent,
    PagesComponent,
    PrivacyPolicyComponent,
    ResourcesComponent,
    JobDetailComponent,
    JobListComponent,
    FurnitureAssemblyComponent,
    InsuranceComponent,
    FaqComponent,
    PostListComponent,
    PostDetailComponent,
    TruckingComponent,
    BoxesSuppliesComponent,
    PackingServiceComponent,
    JunkRemovalComponent,
    StorageComponent,
    MovingComponent,
    ShippingCourierComponent,
    DeliveryServiceComponent,
    DeliveryFormComponent,
    FinalComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SwiperModule,
    SharedModule,
    FooterComponent,
    NzModalModule,
    NzPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NavbarComponent,
    NzSelectModule,
    SubscriptionFormComponent,
    NzRadioModule,
    NzTypographyModule,
  ],
})
export class PagesModule {}
