import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IContactUsRequest } from '../../../core/interfaces/general.interface';
import { ContactUsService } from '../../../core/services/general/contact-us.service';
import { NotificationHandlerService } from '../../../core/services/helper/notification-handler.service';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { HomeService } from '../../../core/services/home.service';
import { IHomePageDataResponse } from '../../../core/interfaces/home.interface';
import { HubSpotChatService } from '../../../core/services/hubspot-chat.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})

export class ContactUsComponent implements OnInit,OnDestroy{
  public display: DisplayType | null = this.displayService.display;
  contactForm: FormGroup;
  isSubmitted = false;
  contactUsSubmitted = false;
  code: string;
  info: IHomePageDataResponse;

  constructor(
    private hubspotChatService: HubSpotChatService,
    private readonly displayService: DisplayService,
    private readonly contactUsService: ContactUsService,
    private readonly notificationHandlerService: NotificationHandlerService,
    private homeService: HomeService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.getHomeInfo();
    this.hubspotChatService.loadScript();
  }

  loadAndOpenChat() {
    this.hubspotChatService.openChat();
  }

  onContactSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid) {
      const data: IContactUsRequest = {
        name: form.value.name,
        phone: form.value.phone.toString(),
        email: form.value.email,
        content: form.value.message,
      };
      this.contactUsService.createContactus(data).subscribe((res) => {
        if (res.status === 200) {
          this.contactUsSubmitted = true;
          this.code = res.response.code;
          this.notificationHandlerService.showNotification(
            'success',
            'Success',
            'Your message is sent!'
          );
          form.reset();
          Object.keys(form.controls).forEach((key) => {
            form.get(key).setErrors(null);
          });
        }
      });
    } else {
      return;
    }
  }

  getHomeInfo() {
    this.homeService.getHomeInfo().subscribe((res) => {
      if (res.status === 200) this.info = res.response;
    });
  }

  ngOnDestroy(): void {
    this.hubspotChatService.closeChat();
  }
}
