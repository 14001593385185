import { Component,OnInit } from '@angular/core';
import { DisplayType } from '../../../../core/types/display.type';
import { DisplayService } from '../../../../shared/services/display.service';
import { IVehicleTypes } from '../../../../core/interfaces/vehicle.interface';
import { VehicleService } from '../../../../core/services/general/vehicle.service';

@Component({
  selector: 'app-delivery-service',
  templateUrl: './delivery-service.component.html',
  styleUrls: ['./delivery-service.component.scss'],
})
export class DeliveryServiceComponent implements OnInit{
  vehicleTypes: IVehicleTypes[] = [];
  public display: DisplayType | null = this.displayService.display;
  show: boolean = false;
  vehicleDetails;
  constructor(
    private readonly displayService: DisplayService,
    private vehicleService: VehicleService,
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  selectVehicle(vehicleTypesId: number) {
    this.vehicleTypes.forEach((item, i) => {
      item.isSelect = vehicleTypesId === i + 1;
    });
  }

  ngOnInit(): void {
    this.getVehicles();
  }

  viewDetails(vehicleTypesId: number) {
    this.show = !this.show;
    this.vehicleDetails = this.vehicleTypes.find(
      (n: any) => n.id === vehicleTypesId
    );
  }

  getVehicles() {
    this.vehicleService.getVehicles().subscribe((res) => {
      res.response.map((i) => {
        i.isSelect = false;
        this.vehicleTypes.push(i);
      });
    });
  }

}
