import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import {
  IHomePageDataResponse,
  IHomePageTestemonialResponse,
} from '../../../core/interfaces/home.interface';
import SwiperCore, { SwiperOptions, Pagination, Scrollbar, A11y } from 'swiper';
import { MovingConcerns } from '../../../core/consts/services.const';
import {
  bounceInLeftOnEnterAnimation,
  slideInUpOnEnterAnimation,
  slideInLeftOnEnterAnimation,
  slideInRightOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  fadeInOnEnterAnimation,
  bounceInUpOnEnterAnimation,
  fadeInUpOnEnterAnimation,
  fadeInDownOnEnterAnimation,
  jelloOnEnterAnimation,
} from 'angular-animations';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { HomeService } from '../../../core/services/home.service';
SwiperCore.use([Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-moving',
  templateUrl: './moving.component.html',
  styleUrls: ['./moving.component.scss'],
  animations: [
    slideInLeftOnEnterAnimation({ anchor: 'slideInLeft' }),
    slideInRightOnEnterAnimation({ anchor: 'slideInRigh' }),
    slideInUpOnEnterAnimation({ anchor: 'slideInUp' }),
    fadeInUpOnEnterAnimation({ anchor: 'fadeInUp' }),
  ],
})
export class MovingComponent implements OnInit {
  private displayChanged: Subscription;
  movingConcerns = MovingConcerns;
  info: IHomePageDataResponse;
  public display: DisplayType | null = this.displayService.display;
  testimonials: IHomePageTestemonialResponse[];
  constructor(
    private readonly displayService: DisplayService,
    private homeService: HomeService
  ) {
    this.displayChanged = this.displayService.onDisplayChanged.subscribe(
      (display: DisplayType) => {
        this.display = display;
      }
    );
  }

  ngOnInit() {
    this.getTestimonialsInfo();
  }

  customerConfig: SwiperOptions = {
    allowSlideNext: true,
    direction: 'horizontal',
    grabCursor: true,
    loop: true,
    spaceBetween: 50,
    navigation: false,
    pagination: {
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      },
    },
  };

  isIntersecting(status: boolean, intersection: any) {}

  onSwiper(swiper: any) {}
  onSlideChange() {}

  getTestimonialsInfo() {
    this.homeService.getHomeInfo().subscribe((res) => {
      if (res.status === 200) {
        this.info = res.response;
        this.testimonials = this.info.testimonials;
      }
    });
  }
}
