import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../../core/services/general/faq.service';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IContactUsRequest } from '../../../core/interfaces/general.interface';
import { ContactUsService } from '../../../core/services/general/contact-us.service';
import { NotificationHandlerService } from '../../../core/services/helper/notification-handler.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  public display: DisplayType | null = this.displayService.display;
  Items: any;
  faqs: any;
  categoryName: any;
  isActive = false;
  faqForm: FormGroup;
  isSubmitted = false;
  contactUsSubmitted = false;
  code: string;

  constructor(
    private readonly displayService: DisplayService,
    private faqService: FaqService,
    private readonly contactUsService: ContactUsService,
    private readonly notificationHandlerService: NotificationHandlerService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
    this.faqForm = new FormGroup({
      name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.getFaqs();
  }

  getFaqs() {
    this.faqService.getFaqs().subscribe((res) => {
      this.faqs = res.response;
      this.getitems(this.faqs[0].name);
      this.faqs[0].faqs[0].isSelect = true;
    });
  }

  onSearch(event: Event) {
    const searchQuery = (
      event.target as HTMLInputElement
    )?.value?.toLowerCase();
    if (!searchQuery) {
      this.getitems(this.categoryName);
    } else {
      this.Items = [];
      this.faqs.forEach((item: any) => {
        const matchingFaqs = item.faqs.filter((n: any) => {
          return (
            n.question.toLowerCase().includes(searchQuery) ||
            n.answer.toLowerCase().includes(searchQuery)
          );
        });
        if (matchingFaqs.length > 0) {
          matchingFaqs.forEach((faq: any) => {
            this.Items.push({
              question: faq.question,
              answer: faq.answer,
            });
          });
        }
      });
    }
  }

  getitems(categoryName: any) {
    if (typeof categoryName === 'string') {
      this.categoryName = categoryName;
    } else {
      this.categoryName = (categoryName.target as HTMLSelectElement)?.value;
    }
    this.Items = this.faqs.find((n: any) => n.name === this.categoryName);
    this.activateButton(this.Items);
    return (this.Items = this.Items.faqs);
  }

  activateButton(button: any): void {
    this.faqs.forEach((b: any) => (b.isActive = false));
    button.isActive = true;
  }

  onFaqSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid) {
      const data: IContactUsRequest = {
        name: form.value.name,
        phone: form.value.phone.toString(),
        email: form.value.email,
        content: form.value.message,
      };
      this.contactUsService.createContactus(data).subscribe((res) => {
        if (res.status === 200) {
          this.contactUsSubmitted = true;
          this.code = res.response.code;
          this.notificationHandlerService.showNotification(
            'success',
            'Success',
            'Your message is sent!'
          );
          form.reset();
          Object.keys(form.controls).forEach(key => {
            form.get(key).setErrors(null) ;
          });
        }
      });
    } else {
      return;
    }
  }
}
