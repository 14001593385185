<div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
        <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-shipping-courier/Shipping_3xl.png"
                alt="Shipping" class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'TABLET'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-shipping-courier/Shipping_medium.png"
                alt="Shipping" class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-shipping-courier/Shipping_small.png"
                alt="Shipping" class="m-auto w-full ">
        </ng-container>
    </div>
    <div class="-translate-y-6 text-center px-4 md:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">SHIPPING & COURIER</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">Affordable rates. Exceptional support. Reliable
            solutions.
        </h1>
        <p class="mx-auto text-lg 3xl:w-9/12 text-center text-center my-3 mb-14">Prestige Courier is your go-to provider for
            affordable shipping rates and exceptional customer support. Our easy-to-use price comparison platform allows
            individuals and businesses to access the cheapest courier and LTL rates available. With Prestige Courier,
            you can rely on our discounted rates and full-service support team to meet your daily shipping needs.
        </p>
    </div>
    <div class="mx-auto 3xl:w-4/5 px-4 laptop:px-0 mb-28 mt-12">
        <form [formGroup]="shippingForm" (ngSubmit)="onShippingSubmit(shippingForm)" action="" class="flex flex-wrap laptop:flex-nowrap gap-6 justify-center items-center">
            <div class="w-full laptop:w-auto flex justify-center laptop:justify-start gap-4 items-center">
                <p class="text-lg font-bold basis-10 laptop:basis-auto">From</p>
                <div class="w-[128px] md:w-[188px] laptop:w-[128px] 3xl:w-[188px]">
                    <input class="w-full placeholder:text-left" type="text" name="Country" placeholder="Country"
                        formControlName="country" />
                </div>
                <div class="w-[118px] md:w-[140px] laptop:w-[118px] 3xl:w-[140px]">
                    <input class="w-full placeholder:text-left" type="text" name="postCode" placeholder="Postal Code"
                        formControlName="postCode" />
                </div>
            </div>
            <div class="w-full laptop:w-auto flex justify-center laptop:justify-start gap-4 items-center">
                <p class="text-lg font-bold basis-10 laptop:basis-auto">To</p>
                <div class="w-[128px] md:w-[188px] laptop:w-[128px] 3xl:w-[188px]">
                    <input class="w-full placeholder:text-left" type="text" name="Country" placeholder="Country"
                        formControlName="country" />
                </div>
                <div class="w-[118px] md:w-[140px] laptop:w-[118px] 3xl:w-[140px]">
                    <input class="w-full placeholder:text-left" type="text" name="postCode" placeholder="Postal Code"
                        formControlName="postCode" />
                </div>
            </div>
            <button type="submit" class="w-[133px] uppercase bg-red-5 text-basic-0">Find price
            </button>
        </form>
    </div>
    <div class="text-center my-24">
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto laptop:w-9/12">Prestige Shipping and Courier Offers:
        </h1>
    </div>
    <div class="grid grid-cols-1 gap-y-20 mx-auto laptop:w-10/12 px-4 laptop:px-0 mb-16">
        <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center">
            <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start">
                <h2
                    class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left ">
                    Prestige Courier</h2>
                <h3 class="mb-4 text-center 3xl:text-left">(Same day Pickup and drop off, 24/7 Dispatch)</h3>
                <p class="text-lg text-center 3xl:text-left">Our courier company is proud to offer a range of
                    reliable and cost-effective services In London ON, and surrounding areas. Whether you need documents
                    delivered across town or a larger shipment transported across the province, we've got you covered.
                    Our team of experienced and professional couriers is committed to providing our customers with
                    exceptional service, every time. We understand the importance of timely and secure deliveries, which
                    is why we go above and beyond to ensure that your packages arrive at their destination on time and
                    in perfect condition.
                </p>
            </div>
            <img src="https://prestige-movers.s3.amazonaws.com/services-shipping-courier/PrestigeCourier.png"
                alt="PrestigeCourier" class="order-1 3xl:order-2">
        </div>
        <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center">
            <img src="https://prestige-movers.s3.amazonaws.com/services-shipping-courier/SmallParcel.png"
                alt="SmallParcel">
            <div class=" flex flex-col items-center 3xl:items-start">
                <h2
                    class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-right">
                    Small Parcel Shipping</h2>
                <h3 class="mb-4 text-center 3xl:text-left">(Up to 85% discounted rates from top carriers)</h3>
                <p class="text-lg text-center 3xl:text-left">We're committed to helping individuals and businesses
                    to save money on their shipping costs. By leveraging our extensive network and strong partnerships
                    with top couriers, we've secured deeply discounted rates for small parcel shipping. You'll
                    immediately have access to the cheapest rates available from trusted carriers like Canada Post,
                    Loomis Express, Purolator, FedEx, DHL, and UPS. Our platform enables you to compare prices and find
                    the best rates for your specific region and shipping lane. Let Prestige Courier help you find a
                    better price for shipping small parcels.
                </p>
            </div>
        </div>
        <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center">
            <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start ">
                <h2
                    class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left">
                    LTL, FTL, flatbed and container shipping </h2>
                <h3 class="mb-4 text-center 3xl:text-left">(Compare Shipping Costs from 100+ carriers)</h3>
                <p class="text-lg text-center 3xl:text-left">We simplify the process of getting freight quotes with
                    our user-friendly platform. Simply enter your shipment's origin, destination, and details, and get
                    options from hundreds of carriers in a matter of seconds. Our quotes are all-inclusive and ready to
                    book, making it easy for wholesalers, manufacturers, and distributors to ship in Canada. Whether you
                    need less-than-truckload (LTL), full truckload (FTL), or heavy haul shipping, our platform can
                    provide you with the best online quotes from hundreds of carriers. Think of us as the Expedia of
                    freight!
                </p>
            </div>
            <img src="https://prestige-movers.s3.amazonaws.com/services-shipping-courier/LTLFTL.png"
                alt="LTLFTL" class="order-1 3xl:order-2">
        </div>
    </div>
</div>
<app-subscription-form [title]="'Stay Tuned with Prestige!'" [type]="'shipping'"
  [description]="'Your email will be used for keeping you updated on our latest news, shipping updates and new features!'"></app-subscription-form>