import { Component, Input, OnInit } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../services/display.service';
import { SubscriptionService } from '../../../core/services/general/blog/subscription.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ISubscriptionRequest } from '../../../core/interfaces/general.interface';
import { NotificationHandlerService } from '../../../core/services/helper/notification-handler.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-subscription-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss'],
})
export class SubscriptionFormComponent implements OnInit {
  public display: DisplayType | null = this.displayService.display;
  @Input() title: string;
  @Input() type: string;
  @Input() description: string;
  subscriptionForm: FormGroup;
  constructor(
    private readonly displayService: DisplayService,
    private subscriptionService: SubscriptionService,
    private notificationHandlerService: NotificationHandlerService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.subscriptionForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  onSubscribeSubmit(form: FormGroup) {
    if (form.valid) {
      const data: ISubscriptionRequest = {
        email: form.value.email,
        type: this.type,
      };
      this.subscriptionService.subscription(data).subscribe((res) => {
        if (res.status === 200 || res.status === 201) {
          this.notificationHandlerService.showNotification(
            'success',
            'Success',
            'Subscribe successfully!'
          );
        }
      });
    }
  }
}
