import { Component } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { ResourceItems } from '../../../core/consts/resources.const';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent {
  public display: DisplayType | null = this.displayService.display;
  resources = ResourceItems;
  constructor(
    private readonly displayService: DisplayService,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NzModalService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  selectService(serviceName: string) {
    switch (serviceName) {
      case 'Blog':
        this.router.navigate(['/post-list'], { relativeTo: this.route });
        break;
      case 'FAQ':
        this.router.navigate(['/faq'], { relativeTo: this.route });
        break;
      case 'Terms and Policies':
        this.router.navigate(['/privacy-policy'], { relativeTo: this.route });
        break;
      case 'Downloads':
        this.modal.warning({
          nzTitle: 'Oops! This Page is Not Available',
          nzContent: `We're sorry, but the page you're trying to access is currently not available.`,
        });
        break;
      case 'Storage Calculator':
        this.modal.warning({
          nzTitle: 'Oops! This Page is Not Available',
          nzContent: `We're sorry, but the page you're trying to access is currently not available.`,
        });
        break;
      case 'Packing Supply Calculator':
        this.modal.warning({
          nzTitle: 'Oops! This Page is Not Available',
          nzContent: `We're sorry, but the page you're trying to access is currently not available.`,
        });
        break;
      default:
        break;
    }
  }
}
