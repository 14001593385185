import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../resource.service';
import { Observable } from 'rxjs';
import { GenericDataResponse } from '../../../interfaces/generic-response.interface';
import { IBlogCategoryResponse } from '../../../interfaces/blog.interface';

@Injectable({
  providedIn: 'root',
})
export class BlogCategoryService extends ResourceService<IBlogCategoryResponse> {
  private readonly endPoint = '/blog-category';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBlogCategory(): Observable<GenericDataResponse<IBlogCategoryResponse[]>> {
    return this.get(this.endPoint);
  }
}
