import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  bounceInLeftOnEnterAnimation,
  slideInUpOnEnterAnimation,
  slideInLeftOnEnterAnimation,
  slideInRightOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  fadeInOnEnterAnimation,
  bounceInUpOnEnterAnimation,
  fadeInUpOnEnterAnimation,
  fadeInDownOnEnterAnimation,
  jelloOnEnterAnimation,
} from 'angular-animations';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SwiperCore, { SwiperOptions, Pagination, Scrollbar, A11y } from 'swiper';
import { DisplayService } from '../../../shared/services/display.service';
import { Subscription } from 'rxjs';
import { DisplayType } from '../../../core/types/display.type';
import { HomeService } from '../../../core/services/home.service';
import {
  IHomePageDataResponse,
  IHomePageTestemonialResponse,
} from '../../../core/interfaces/home.interface';
import { ServicesService } from '../../../core/services/services.service';
import { IServiceCategoryResponse } from '../../../core/interfaces/services.interface';
import { ActivatedRoute, Router } from '@angular/router';

SwiperCore.use([Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    bounceInUpOnEnterAnimation({ anchor: 'bounceInUpOnEnter', delay: 1000 }),
    slideInLeftOnEnterAnimation({ anchor: 'slideInLeft' }),
    slideInRightOnEnterAnimation({ anchor: 'slideInRigh' }),
    slideInUpOnEnterAnimation({ anchor: 'slideInUp' }),
    bounceInLeftOnEnterAnimation({ anchor: 'bounceInLeft', delay: 200 }),
    fadeInOnEnterAnimation({ anchor: 'enter', duration: 1500, delay: 100 }),
    fadeOutOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 200 }),
    fadeInOnEnterAnimation({ delay: 2000 }),
    fadeInUpOnEnterAnimation({ anchor: 'fadeInUp' }),
    fadeInDownOnEnterAnimation({ anchor: 'fadeInDown' }),
    jelloOnEnterAnimation({
      anchor: 'jelloOnEnter',
      duration: 5000,
      delay: 200,
    }),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  services: IServiceCategoryResponse[];
  testimonials: IHomePageTestemonialResponse[];
  desktopMode: boolean;
  slideCount: number = 2;
  info: IHomePageDataResponse;
  delay: number;
  duration: number;
  private displayChanged: Subscription;
  public display: DisplayType | null = this.displayService.display;

  config: SwiperOptions = {
    allowSlideNext: true,
    direction: 'horizontal',
    grabCursor: true,
    loop: true,
    spaceBetween: 50,
    navigation: false,
    pagination: {
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 1,
      },
    },
  };

  customerConfig: SwiperOptions = {
    allowSlideNext: true,
    direction: 'horizontal',
    grabCursor: true,
    loop: true,
    spaceBetween: 50,
    navigation: false,
    pagination: {
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      },
    },
  };

  constructor(
    private readonly displayService: DisplayService,
    public el: ElementRef,
    private homeService: HomeService,
    private servicesService: ServicesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.displayChanged = this.displayService.onDisplayChanged.subscribe(
      (display: DisplayType) => {
        this.display = display;
      }
    );
  }

  ngOnInit() {
    this.getHomeInfo();
    this.getServices();
    gsap.registerPlugin(ScrollTrigger, Draggable);
    this.initScrollTriggers();
  }

  isIntersecting(status: boolean, intersection: any) {}

  onSwiper(swiper: any) {}
  onSlideChange() {}

  @HostListener('window:scroll', []) onWindowScroll() {
    this.reveal();
  }
  reveal() {
    const reveals = document.querySelector('.speed') as HTMLElement | null;
    const parent = document.querySelector('.parent') as HTMLElement | null;
    if (!reveals) {
      return;
    }
    const windowHeight = window.innerHeight;
    const elementTop = parent.getBoundingClientRect().top;
    const elementVisible = 20;
    if (elementTop < windowHeight - elementVisible) {
      reveals.classList.add('active');
    }
  }

  initScrollTriggers() {
    let speed = 100;

    /*  SCENE 1 */
    let scene1 = gsap.timeline();
    ScrollTrigger.create({
      animation: scene1,
      start: 'top top',
      end: '35% 0',
      scrub: 1,
    });

    // hills animation
    scene1.to('#h1-8', { y: -2 * speed, x: 0 * speed }, 0);
    scene1.to('#h1-12', { y: -2 * speed, x: 0 * speed }, 0);
    scene1.to('#h1-13', { y: -3 * speed, x: 0 * speed }, 0);
    scene1.to('#h1-9', { y: -3 * speed, x: 0 * speed }, 0);

    /*   SCENE 2  */
    let scene2 = gsap.timeline();
    ScrollTrigger.create({
      animation: scene2,
      start: 'top top',
      end: '35% 0',
      scrub: 1,
    });

    scene2.fromTo('#h2-1', { y: 130 }, { y: 0 }, 0);
    scene2.fromTo('#h2-2', { y: 160 }, { y: 0 }, 0);
    scene2.fromTo('#h2-3', { y: 130 }, { y: 0 }, 0);
    scene2.fromTo('#h2-4', { y: 90 }, { y: 0 }, 0);
    scene2.fromTo('#h2-5', { y: 10 }, { y: 0 }, 0);
  }

  getHomeInfo() {
    this.homeService.getHomeInfo().subscribe((res) => {
      if (res.status === 200) {
        this.info = res.response;
        this.testimonials = this.info.testimonials;
        const general = res.response.general;
        const prestigeInfo = res.response.home_page;
        localStorage.setItem('general', JSON.stringify(general));
        localStorage.setItem('prestige-info', JSON.stringify(prestigeInfo));
      }
    });
  }

  getServices() {
    this.servicesService.getServices().subscribe((res) => {
      this.services = res.response;
    });
  }

  selectService(serviceName: string) {
    const serviceMappings = {
      Moving: 'moving',
      Delivery: 'delivery-service',
      Storage: 'storage',
      'Shipping & Courier': 'shipping-courier',
      'Boxes and Supplies': 'boxes-supplies',
      'Packing Services': 'packing-service',
      Trucking: 'trucking',
      'Junk Removal': 'junk-removal',
      'Furniture Assembly': 'furniture-assembly',
    };

    const routePath = serviceMappings[serviceName];
    if (routePath) {
      this.router.navigate([routePath], { relativeTo: this.route });
    }
  }
  ngOnDestroy(): void {}
}
