import { Component } from '@angular/core';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-packing-service',
  templateUrl: './packing-service.component.html',
  styleUrls: ['./packing-service.component.scss']
})
export class PackingServiceComponent {
 public display: DisplayType | null = this.displayService.display;
  constructor(private readonly displayService: DisplayService) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }
}
