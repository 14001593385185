interface BoxesInterface {
  id: number;
  name: string;
  img: string;
  description?: string;
}

export const BoxesSupplies: BoxesInterface[] = [
  {
    id: 0,
    name: 'MOVING KITS',
    img: 'https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/Icons___MovingKits.png',
  },
  {
    id: 1,
    name: 'MOVING BOXES',
    img: 'https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/Icons___MovingBoxes.png',
  },
  {
    id: 2,
    name: 'TV BOXES & PROTECTIVE KITS',
    img: 'https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/Icons___TVBoxes_ProtectiveKits.png',
  },
  {
    id: 3,
    name: 'BUBBLE WRAP',
    img: 'https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/Icons___BubbleWrap.png',
  },
  {
    id: 4,
    name: 'TAPES & LABELS',
    img: 'https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/Icons___Tapes_Labels.png',
  },
  {
    id: 5,
    name: 'FURNITURE COVERS',
    img: 'https://prestige-movers.s3.amazonaws.com/services-boxes-supplies/Icons___FurnitureCover.png',
  },
];

export const MovingConcerns: BoxesInterface[] = [
  {
    id: 0,
    name: 'Our Movers',
    img: 'https://prestige-movers.s3.amazonaws.com/services-moving/OurMovers.png',
    description:
      'Our extensively trained movers will efficiently handle all your items with their strategic loading and unloading and secure transportation. Beyond their technical proficiency, they will also be there for any questions and concerns you may have.',
  },
  {
    id: 1,
    name: 'Insurance and Coverage',
    img: 'https://prestige-movers.s3.amazonaws.com/services-moving/Insurance%26Coverage.png',
    description:
      'We are delighted to inform you that we carry a 5 million liability and cargo insurance, and you can insure any item of your choice!',
  },
  {
    id: 2,
    name: 'Last-Minute Move ',
    img: 'https://prestige-movers.s3.amazonaws.com/services-moving/LastMinuteMove.png',
    description:
      'Emergencies happen, and we get it. To arrange an urgent moving service, all you need to do is contact us. We’ll do our best to help you out. ',
  },
  {
    id: 3,
    name: 'Our Equipment',
    img: 'https://prestige-movers.s3.amazonaws.com/services-moving/OurEquipment.png',
    description:
      'Our fully sanitized trucks are available in different sizes from 16ft to 26ft. Our moving dollies and other equipment will lift and move any heavy appliances. And our floor runners will make sure your floor remains intact and damage-free!',
  },
  {
    id: 4,
    name: 'Special Handling',
    img: 'https://prestige-movers.s3.amazonaws.com/services-moving/SpecialHandling.png',
    description:
      'From white-glove services to lifting heavy objects or protecting your valuables, our company sees to the safety of your belongings. You can always request a separate moving service for special items and valuables for your own ease of mind! ',
  },
  {
    id: 5,
    name: 'Charges ',
    img: 'https://prestige-movers.s3.amazonaws.com/services-moving/Charges.png',
    description:
      'Getting a quote should give you a fairly accurate estimate of your moving charges. However, depending on the weight of your items, space restrictions and urgency of the move, there could be some extra charges for your request.  ',
  },
];
