<footer class="bg-darkNavy py-20 text-white">
  <div class="container mx-auto px-4 md:px-8 laptop:px-16">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-14 lg:gap-4">
      <div class="hidden lg:block">
        <img
          src="/assets/images/logos/logo_light_3xl.svg"
          alt="Prestige Logo"
        />
        <p class="text-sm font-normal leading-[18px] mt-10 w-10/12">
          Copyright © {{ year }}
          Prestige Movers. All rights reserved.
        </p>
      </div>
      <div>
        <h3 class="text-orange">Services</h3>
        <div class="mt-5">
          <ul class="grid grid-cols-2 md:grid-cols-1 gap-4 md:gap-2">
            <li *ngFor="let item of serviceItems">
              <a
                [routerLink]="item.route"
                class="text-lg font-normal leading-[22px] text-white hover:text-orange"
              >
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h3 class="text-orange">Quick Links</h3>
        <div class="mt-5">
          <ul class="grid grid-cols-2 md:grid-cols-1 gap-4 md:gap-2">
            <li *ngFor="let item of quikLinks">
              <ng-container *ngIf="item.route.startsWith('http'); else internalLink">
                <a [href]="item.route"
                   class="text-lg font-normal leading-[22px] text-white hover:text-orange"
                   target="_blank"
                   rel="noopener noreferrer">
                  {{ item.name }}
                </a>
              </ng-container>
              <ng-template #internalLink>
                <a [routerLink]="item.route"
                   class="text-lg font-normal leading-[22px] text-white hover:text-orange">
                  {{ item.name }}
                </a>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h3 class="text-orange">Contact Us</h3>
        <div class="leading-[22px] text-lg">
          <ul class="grid grid-cols-1 gap-8 lg:gap-3 mt-5">
            <li>
              <p class="font-bold mb-[10px]">Call Center</p>
              <p class="font-normal">Give Us a Call</p>
              <a [href]="'tel:'+[info?.home_page[0].value]" class="font-normal">{{ info?.home_page[0].value }}</a>
            </li>
            <li>
              <p class="font-bold mb-[10px]">Our Office Location</p>
              <p class="font-normal">
                {{ info?.home_page[1].value }}
              </p>
            </li>
            <li>
              <p class="font-bold mb-[10px]">Working Hours</p>
              <p class="font-normal inline-block">
                {{ info?.home_page[3].value }}
              </p>
              <p class="font-normal inline-block ml-6 md:ml-0 laptop:ml-6">
                {{ info?.home_page[2].value }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="w-full lg:hidden">
      <div>
        <p class="text-sm font-normal leading-[18px] mt-10 md:w-10/12">
          Copyright © {{ year }} Prestige Movers. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</footer>
