import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarItems } from '../../../core/consts/navbar-items.const';
import { RouterModule } from '@angular/router';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';
import { HomeService } from '../../../core/services/home.service';
import { IHomePageDataResponse } from '../../../core/interfaces/home.interface';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  public display: DisplayType | null = this.displayService.display;
  nabvarItems = NavbarItems;
  ToggleState = false;
  info: IHomePageDataResponse;
  searchOpen = false;
  constructor(
    private readonly displayService: DisplayService,
    private homeService: HomeService
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit() {
    this.getHomeInfo();
  }
  ToggleMenu() {
    this.ToggleState = !this.ToggleState;
    
    if (this.ToggleState) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  getHomeInfo() {
    this.homeService.getHomeInfo().subscribe((res) => {
      if (res.status === 200) this.info = res.response;
    });
  }

  openSearchbar() {
    this.searchOpen = true;
  }
}
