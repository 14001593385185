import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IContactUsRequest, IContactUsResponse } from '../../interfaces/general.interface';
import { GenericDataResponse } from '../../interfaces/generic-response.interface';
import { ResourceService } from '../resource.service';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService extends ResourceService<IContactUsResponse,IContactUsRequest> {
  private readonly endPoint = '/contact-us';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  createContactus(data:IContactUsRequest): Observable<GenericDataResponse<IContactUsResponse>>{
    return this.post(this.endPoint,data).pipe();
  }

}
