import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

interface ToastInterface {
  type: string;
  title: string;
  message: string;
  duration: number;
}

@Component({
  selector: 'app-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [CommonModule],
})
export class ToastComponent implements OnInit {
  @Input() type: 'success' | 'danger' | 'warning' | 'info';
  @Input() title: string;
  @Input() message: string;
  @Input() duration: number;
  showToast = true;
  constructor() {}
  ngOnInit(): void {
    setTimeout(() => {
      this.showToast = false;
    }, this.duration);
  }
}
