import { Component, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DisplayType } from '../../../core/types/display.type';
import { DisplayService } from '../../../shared/services/display.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent {
  public display: DisplayType | null = this.displayService.display;

  constructor(private readonly displayService: DisplayService , public el: ElementRef) {
     this.displayService.onDisplayChanged.subscribe(
      (display: DisplayType) => {
        this.display = display;
      }
    );
  }

}
