import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationType } from '../../types/notification.type';

@Injectable({
  providedIn: 'root',
})
export class NotificationHandlerService {
  constructor(private notification: NzNotificationService) {}

  showNotification(type: NotificationType, heading: string, message: string): void {
    this.notification.create(type, heading, `<span>${message}</span>`);
  }
}
