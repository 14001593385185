<div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
        <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-trucking/trucking.png" alt="trucking"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'TABLET'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-trucking/Trucking_medium.png" alt="trucking"
                class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-trucking/PrestigeMovers_Website_Trucking_Banners___Header_M%201.png"
                alt="trucking" class="m-auto w-full">
        </ng-container>
    </div>
    <div class="-translate-y-3 text-center px-4 md:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">Prestige Trucking</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">Optimized solutions for your transportation needs
        </h1>
        <p class="mx-auto text-lg 3xl:w-9/12 text-center my-3 mb-24">We have a dedicated team trained to provide the
            most optimized solutions for your business transportation needs. Our over-the-road services include Full
            Truck Load (FTL), Less Than Truckload (LTL), Flat Bed Trucking, and a dedicated fleet for your business. We
            cover a wide range of freight types, including fresh produce, pharmaceuticals, fresh or frozen meats,
            construction material, automotive, and other dry or temperature-controlled goods. Our top priorities are
            providing competitive pricing and on-time delivery, and we take pride in having achieved both to our
            customers' satisfaction. Our company has access to an extensive network of top transportation providers in
            the industry, which enables us to shop rates across 100 carriers and allow you to book and schedule your
            shipment online.</p>
    </div>
    <div class="mx-auto laptop:w-11/12 px-4 laptop:px-0 mb-16">
        <div class="grid grid-cols-1 gap-y-20">
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        3PL/ Freight Forwarding</h2>
                    <p class="text-lg text-center 3xl:text-left">With access to an extensive network of 100+ careers
                        and transportation service providers, we are here to help you send any load to any destination.
                        With our easy online booking system, you can quickly and easily book your LTL, FTL, flat bed,
                        heavy haul, dry goods, refrigerated produce, and oversized cargo at any time.</p>
                    <!-- <button routerLink="/services" class="text-center uppercase bg-red-5 text-basic-0 my-8">Get a
                        Quote</button> -->
                </div>
                <img src="https://prestige-movers.s3.amazonaws.com/services-trucking/PrestigeMovers_Website_Trucking_Images___3PLFreightForwarding%201.png"
                    alt="3PLFreightForwarding" class="order-1 3xl:order-2">
            </div>
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <img src="https://prestige-movers.s3.amazonaws.com/services-trucking/PrestigeMovers_Website_Trucking_Images___Dedicated%20Fleet%201.png"
                    alt="Dedicated Fleet">
                <div class=" flex flex-col items-center 3xl:items-end">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-right mb-4">
                        Dedicated Fleet</h2>
                    <p class="text-lg text-center 3xl:text-right">Businesses seeking a reliable transportation
                        option often choose our dedicated fleet service, which guarantees capacity on an ongoing
                        basis. Our committed equipment and drivers ensure your shipment is delivered on time, every
                        time, without requiring you to invest additional capital.
                    </p>
                    <button routerLink="/contact-us" class="text-center uppercase bg-red-5 text-basic-0 my-8">Contact
                        Us</button>
                </div>
            </div>
            <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 justify-items-center">
                <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start ">
                    <h2
                        class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                        On-Demand Trucking</h2>
                    <p class="text-lg text-center 3xl:text-left">We provide an on-demand trucking service with our
                        extensive fleet of moving trucks, straight trucks, flatbed trucks, and dumping flatbeds. Our
                        reliable and experienced drivers are ready to assist with your transportation needs at any time,
                        ensuring prompt and efficient deliveries. Whether you need to transport equipment, furniture, or
                        any other goods, our diverse fleet can accommodate any type of shipment. With our on-demand
                        trucking service, you can count on us to deliver your goods on time and on budget.
                    </p>
                    <a [href]="'tel:(519) 444-4444'">
                        <button class="text-center uppercase bg-red-5 text-basic-0 my-8">Call Dispatch</button>
                    </a>
                </div>
                <img src="https://prestige-movers.s3.amazonaws.com/services-trucking/PrestigeMovers_Website_Trucking_Images___On%20Demand%20Trucking%201.png"
                    alt="Demand Trucking" class="order-1 3xl:order-2">
            </div>
        </div>
    </div>
</div>