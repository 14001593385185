import { Component } from '@angular/core';
import { IBlogResponse } from '../../../../core/interfaces/blog.interface';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../../../../core/services/general/blog/blog-post.service';
import { DisplayType } from '../../../../core/types/display.type';
import { DisplayService } from '../../../../shared/services/display.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss'],
})
export class PostDetailComponent {
  public display: DisplayType | null = this.displayService.display;
  post: IBlogResponse;
  sanitizedContent: SafeHtml;

  constructor(
    private readonly displayService: DisplayService,
    private activatedRoute: ActivatedRoute,
    private blogService: BlogService,
    private sanitizer: DomSanitizer
  ) {
    this.displayService.onDisplayChanged.subscribe((display: DisplayType) => {
      this.display = display;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((res) => {
      const id = +res['params']['id'];
      const slug = res['params']['slug'];
      this.getBlogById(id, slug);
    });
  }

  getBlogById(id: number, slug: string) {
    this.blogService.getPostById(id, slug).subscribe((res) => {
      if (res.status === 200) {
        this.post = res.response;
        this.sanitizedContent = this.sanitizeHTML(this.post?.content);
      }
    });
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
