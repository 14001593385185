<div class="container mx-auto md:px-8 laptop:px-16">
    <div class="min-h-[310px]">
        <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-packing-service/PackingService_3xl.png"
                alt="PackingService" class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'TABLET'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-packing-service/PackingService_medium.png"
                alt="PackingService" class="m-auto h-full">
        </ng-container>
        <ng-container *ngIf="display === 'MOBILE'">
            <img src="https://prestige-movers.s3.amazonaws.com/services-packing-service/PackingService_small.png"
                alt="PackingService" class="m-auto w-full ">
        </ng-container>
    </div>
    <div class="-translate-y-6 text-center px-4 md:px-0">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">Packing Service</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto 3xl:w-9/12">From dishes to delicate equipment, we pack it
            all!
        </h1>
        <p class="mx-auto text-lg 3xl:w-9/12 text-center my-3 mb-14">When preparing for a move, packing is often
            neglected until the last minute, despite it being one of the most important aspects of a move. While moving
            large items poses its own challenges, protecting small and delicate items properly through packing is
            crucial for a successful move. Prestige Moving & Delivery offers packing rates that fit any budget. Whether
            you're moving everyday household items, fragile belongings, or sophisticated medical equipment, our expert
            staff can pack it all. We guarantee that your items will be prepared and transported safely, and will arrive
            at your new destination damage-free. Trust Prestige Moving & Delivery to handle all your packing needs and
            make your move as seamless as possible.</p>
        <button routerLink="/services/packing" class="mx-auto text-center uppercase bg-red-5 text-basic-0 mt-6">Book
            now</button>
    </div>
    <div class="text-center my-20">
        <h3 class="text-red-5 text-[32px] leading-[38.4px]">All the packing solutions you need!</h3>
        <h1 class="text-darkNavy text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px] mx-auto laptop:w-9/12">Prestige's Packing Crew Offer:
        </h1>
    </div>
    <div class="grid grid-cols-1 gap-y-20 mx-auto laptop:w-10/12 px-4 laptop:px-0 mb-16">
        <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center">
            <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start">
                <h2
                    class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                    Packing Service</h2>
                <p class="text-lg text-center 3xl:text-left">Prestige Moving & Delivery acknowledges that you may have a
                    busy schedule and may not have the time to complete the tiresome task of packing and unpacking all
                    your belongings. Our team of professional packers and movers will arrive on time with all the
                    necessary equipment to assist you. We are dedicated to providing a hassle-free experience for our
                    clients by efficiently packing each box, so that they do not have to worry about forgetting any
                    important items. We also appreciate the importance of timing during this process, and there will be
                    no delays when it comes to loading the items onto the truck. If you're looking for exceptional
                    movers in London, Ontario and the surrounding areas who offer top-notch packing and unpacking
                    services at reasonable prices. Reach out to us today!
                </p>
            </div>
            <img src="https://prestige-movers.s3.amazonaws.com/services-packing-service/Images_PackingService.png"
                alt="PackingService" class="order-1 3xl:order-2">
        </div>
        <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center">
            <img src="https://prestige-movers.s3.amazonaws.com/services-packing-service/Images_UnpackingService.png"
                alt="UnpackingService">
            <div class=" flex flex-col items-center 3xl:items-start">
                <h2
                    class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                    Unpacking Service</h2>
                <p class="text-lg text-center 3xl:text-left">Unpacking is an essential aspect to consider, which is
                    often neglected until after the move. As a component of our local packing service, we provide our
                    customers with an unpacking service as well, which offers various features depending on your
                    preferences.
                    For instance, if you opt for a countertop unpack, we will position your items on the nearest surface
                    as quickly as possible, without any further interference from us, enabling you to feel at home in
                    your new abode. Simply request our Packing & Unpacking service! We will dispose of all debris,
                    leaving you with a fresh space where everything is in its proper location.
                </p>
            </div>
        </div>
        <div class="grid grid-cols-1 3xl:grid-cols-2 gap-8 items-center justify-items-center">
            <div class="order-2 3xl:order-1 flex flex-col items-center 3xl:items-start ">
                <h2
                    class="text-[32px] leading-[38.4px] md:text-[40px] md:leading-[40px] text-center 3xl:text-left mb-4">
                    Packing Supplies</h2>
                <p class="text-lg text-center 3xl:text-left">If you require packing materials, we have an extensive
                    assortment of supplies and custom boxes that will meet your particular requirements. On the day of
                    packing, we will bring all the necessary materials so you won't have to concern yourself with items
                    that might be left behind or missing from our inventory. Entrusting us with this aspect will ensure
                    that everything runs smoothly. Additionally, you can order packing supplies from our website if you
                    wish to do your own packing or want to pack some items before our team of packers arrives at your
                    location.
                </p>
                <button routerLink="/services/packing" class="text-center uppercase bg-red-5 text-basic-0 my-8">Order
                    Now</button>
            </div>
            <img src="https://prestige-movers.s3.amazonaws.com/services-packing-service/Images_PackingSupplies.png"
                alt="PackingSupplies" class="order-1 3xl:order-2">
        </div>
    </div>
</div>
<app-subscription-form [title]="'Stay Tuned with Prestige!'" [type]="'packing'"
    [description]="'Your email will be used for keeping you updated on our latest news, shipping updates and new features!'"></app-subscription-form>