import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  QuikLinks,
  ServiceItems,
} from '../../../core/consts/footer-items.const';
import { RouterModule } from '@angular/router';
import { HomeService } from '../../../core/services/home.service';
import { IHomePageDataResponse } from '../../../core/interfaces/home.interface';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  serviceItems = ServiceItems;
  quikLinks = QuikLinks;
  year: number;
  info: IHomePageDataResponse;

  constructor(private homeService: HomeService) {}
  ngOnInit() {
    this.year = new Date().getFullYear();
    this.getFooterInfo();
  }

  getFooterInfo() {
    this.homeService.getHomeInfo().subscribe((res) => {
      if (res.status === 200) this.info = res.response;
    });
  }
}
