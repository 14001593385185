<section>
    <div class="container mx-auto md:px-8 laptop:px-16">
        <div class="min-h-[310px]">
            <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                <img src="https://prestige-movers.s3.amazonaws.com/faq/FAQ_3xl.png" alt="faq" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'TABLET'">
                <img src="https://prestige-movers.s3.amazonaws.com/faq/FAQ_medium.png" alt="faq" class="m-auto">
            </ng-container>
            <ng-container *ngIf="display === 'MOBILE'">
                <img src="https://prestige-movers.s3.amazonaws.com/faq/FAQ_small.png" alt="faq" class="m-auto w-full">
            </ng-container>
        </div>
        <div class="-translate-y-2/4 laptop:-translate-y-2/4 text-center px-4 md:px-0">
            <h3 class="text-red-5 text-[32px] leading-[38.4px]">FAQs</h3>
            <h1 class="text-gray-5 text-[40px] leading-10 md:text-[64px] md:leading-[64px] laptop:text-[64px] laptop:leading-[76.8px]
            3xl:text-[64px] 3xl:leading-[64px]">Frequently Asked Questions
            </h1>
        </div>
    </div>
</section>
<section class="pb-10 w-full md:w-4/5 laptop:w-11/12 3xl:w-4/5 m-auto">
    <div class="container mx-auto px-4 md:px-8 laptop:px-16">
        <div class="gap-8 grid grid-cols-1 laptop:grid-cols-5">
            <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                <div>
                    <span class="text-lg font-bold text-gray-5 leading-[22px] uppercase">categories</span>
                    <div class="accordion my-1">
                        <div *ngFor="let type of faqs">
                            <button type="button" (click)="getitems(type.name)"
                                class="w-full flex items-center text-left text-sm font-bold px-0" [ngClass]="
                                type.isActive === true
                                  ? 'text-red-5'
                                  : 'text-gray-5'
                              ">
                                {{ type.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="laptop:col-span-4">
                <div class="grid grid-cols-1 laptop:grid-cols-2 items-center">
                    <ng-container *ngIf="display === 'LAPTOP' || display === 'DESKTOP'">
                        <h3 class="text-gray-5 text-[24px] leading-[28.8px] md:text-[32px] md:leading-[38.4px]">{{categoryName}}
                        </h3>
                    </ng-container>
                    <div class="relative">
                        <button class="absolute left-0 px-2 py-0 top-2/4 -translate-y-2/4"><svg width="24" height="25"
                                viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.5 14.5H14.71L14.43 14.23C15.4439 13.054 16.0011 11.5527 16 10C16 8.71442 15.6188 7.45772 14.9046 6.3888C14.1903 5.31988 13.1752 4.48676 11.9874 3.99479C10.7997 3.50282 9.49279 3.37409 8.23192 3.6249C6.97104 3.8757 5.81285 4.49477 4.90381 5.40381C3.99477 6.31285 3.3757 7.47104 3.1249 8.73192C2.87409 9.99279 3.00282 11.2997 3.49479 12.4874C3.98676 13.6752 4.81988 14.6903 5.8888 15.4046C6.95772 16.1188 8.21442 16.5 9.5 16.5C11.11 16.5 12.59 15.91 13.73 14.93L14 15.21V16L19 20.99L20.49 19.5L15.5 14.5ZM9.5 14.5C7.01 14.5 5 12.49 5 10C5 7.51 7.01 5.5 9.5 5.5C11.99 5.5 14 7.51 14 10C14 12.49 11.99 14.5 9.5 14.5Z"
                                    fill="#A6A9B4" />
                            </svg>
                        </button>
                        <input class="w-full !pl-[35px]" type="text"  name="search" placeholder="Search  here" (input)="onSearch($event)" (keyup)="onSearch($event)" />
                    </div>
                    <ng-container *ngIf="display === 'TABLET' || display === 'MOBILE'">
                        <div class="accordion my-1">
                            <nz-select [(ngModel)]="categoryName" (ngModelChange)="getitems($event)">
                                <nz-option *ngFor="let type of faqs" [nzValue]="type.name"
                                    [nzLabel]="type.name"></nz-option>
                            </nz-select>
                        </div>
                    </ng-container>
                </div>
                <div class="accordion my-2" *ngIf="Items">
                    <div *ngFor="let type of Items">
                        <button type="button" (click)="type.isSelect=!type.isSelect"
                            class="w-full flex items-center text-left bg-dun-2 text-gray-7 my-2 laotop:my-1 font-normal leading-6">
                            <i class="fa-solid fa-chevron-right text-xs text-gray-7 mr-3" [ngClass]="
                                type.isSelect === true
                                  ? 'rotate-90'
                                  : ''
                              "></i>
                            {{ type.question }}
                        </button>
                        <div *ngIf="type.isSelect === true" class="text-sm text-gray-5 p-2 pb-3" >
                            {{ type.answer
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bg-dun-5 py-20">
    <div class="container mx-auto px-4 md:px-8 laptop:px-16">
        <div class="w-full md:w-4/5 laptop:w-3/5 m-auto">
            <h2 class="text-gray-5 text-center md:text-left">
                Didn't find your question above?
            </h2>
            <p class="text-darkNavy text-center md:text-left leading-[22.63px]">Please enter your name, phone number and
                email address, then
                proceed to ask your
                question in the message box below. We will answer any questions you may have via email.</p>
            <form
            [formGroup]="faqForm"
            (ngSubmit)="onFaqSubmit(faqForm)"
            class="flex flex-col gap-4 mt-8 text-left"
            >
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    class="w-full"
                    name="name"
                    placeholder="Name"
                    formControlName="name"
                    required
                    [ngClass]="{
                      'invalid:border-red-5':
                        (faqForm.get('name').touched || isSubmitted) &&
                        faqForm.get('name').hasError('required')
                    }"
                  />
                  <span
                    *ngIf="
                      (faqForm.get('name').touched || isSubmitted) &&
                      faqForm.get('name').hasError('required')
                    "
                    class="text-red-5 text-sm leading-4"
                  >
                    name is required
                  </span>
                </div>
                <div>
                  <input
                    class="w-full"
                    type="text"
                    appPhonePrefix
                    name="phoneNumber"
                    placeholder="Phone Number"
                    required
                    formControlName="phone"
                    [ngClass]="{
                      'invalid:border-red-5':
                        (faqForm.get('phone').touched || isSubmitted) &&
                        faqForm.get('phone').hasError('required')
                    }"
                  />
                  <span
                    *ngIf="
                      (faqForm.get('phone').touched || isSubmitted) &&
                      faqForm.get('phone').hasError('required')
                    "
                    class="text-red-5 text-sm leading-4"
                  >
                    phone is required
                  </span>
                </div>
              </div>
              <div>
                <input
                  class="w-full"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  formControlName="email"
                  required
                  [ngClass]="{
                    'invalid:border-red-5':
                      (faqForm.get('email').touched || isSubmitted) &&
                      (faqForm.get('email').hasError('required') ||
                        faqForm.get('email').hasError('email'))
                  }"
                />
                <span
                  *ngIf="
                    (faqForm.get('email').touched || isSubmitted) &&
                    faqForm.get('email').hasError('required')
                  "
                  class="text-red-5 text-sm leading-4"
                >
                  email is required
                </span>
                <span
                  *ngIf="
                    (faqForm.get('email').touched || isSubmitted) &&
                    faqForm.get('email').hasError('email')
                  "
                  class="text-red-5 text-sm leading-4"
                >
                  Enter a valid Email
                </span>
              </div>
              <div>
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Message"
                  class="w-full"
                  formControlName="message"
                  required
                  [ngClass]="{
                    'invalid:border-red-5':
                      (faqForm.get('message').touched || isSubmitted) &&
                      faqForm.get('message').hasError('required')
                  }"
                ></textarea>
                <span
                  *ngIf="
                    (faqForm.get('message').touched || isSubmitted) &&
                    faqForm.get('message').hasError('required')
                  "
                  class="text-red-5 text-sm leading-4"
                >
                  message is required
                </span>
              </div>
              <button
                type="submit"
                class="text-center mx-auto inline-block uppercase bg-red-5 text-basic-0"
              >
                send message
              </button>
            </form>
        </div>
    </div>
</section>
